import React from "react";
import { useNavigate } from "react-router-dom";
import LoginForm from "./LoginForm";
import { IMAGES } from "../../../assets";
import { STRINGS } from "../../../constants";
import { useTranslation } from "react-i18next";

const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="w-full  ">
      <div className="w-[100%]    flex h-[100vh]">
        <div className="w-[50%] relative h-full  bg-[#FE6B010F] lg:block md:hidden sm:hidden hidden  ">
          {/* <AuthPageImage ImageUrl={Image} logo={Logo} /> */}
          <div className="flex flex-col items-center justify-between h-full">
            <img
              src={IMAGES?.logo}
              className="w-auto h-24 bottom-0 relative mt-12"
            />
            <img
              src={IMAGES?.reading_ledy}
              className="w-auto h-96 bottom-0 relative"
            />
          </div>
        </div>
        <div className="w-full   lg:w-[50%]  md:w-full  sm:w-full  px-4   sm:px-28  py-2 flex flex-col justify-center items-center relative">
          <div className=" flex flex-col max-w-[345px]">
            <h2 className="py-1 font-semibold text-3xl">{t(STRINGS.login)}</h2>
            <p className="py-2 text-paragraph pb-3">
              Please enter your details below to continue
            </p>
            <LoginForm />
            {/* <div className="or-section w-1/1 flex justify-center py-2">
              <p className="text-paragraph text-center text-sm text-[#8A8A8A]">
                {t(STRINGS.or_login_with)}
              </p>
            </div>
            <div className=" items-center grid grid-cols-2 gap-2">
              <button className="px-4 py-2  rounded-xl border border-[#E6E6E6] flex items-center gap-2 ">
                <img className="w-8 h-auto" src={IMAGES?.googleIcon} />
                <p>{t(STRINGS.google)}</p>
              </button>
              <button className="px-4 py-2  rounded-xl border border-[#E6E6E6] flex items-center gap-2 ">
                <img className="w-8 h-auto" src={IMAGES?.facebookIcon} />
                <p>{t(STRINGS.facebook)}</p>
              </button>
            </div> */}
          </div>
          {/* <div className="dont_have_account mt-4 text-center ">
            <p className="w-full ps-2 text-sm text-[#8A8A8A] ">
              {t(STRINGS.dont_have_an_account)}
              <span
                onClick={() => navigate("/signup")}
                className="font-bold text-base text-[#480D9B] cursor-pointer ml-2"
              >
                {t(STRINGS.sign_up)}
              </span>
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Login;
