export default {
  please_enter_your_email_and_password:
    "Please enter your email id and password",
  login: "Login",
  email_id: "Email ID",
  password: "Password",
  forgot_password: "Forgot Password?",
  or_login_with: "Or login with...",
  google: "Google",
  facebook: "Facebook",
  dont_have_an_account: `Don't have an account?  `,
  sign_up: "Sign up",
  have_an_account: "Have an account?  ",
  log_in: "Log In",
  submit: "Submit",
  home: "Home",
  myBooks: "My Books",
  quiz: "Quiz",
  account: "Account",
  trending_books: "Trending books",
  newly_added_books: "Newly Added Books ",
  other_books: "Other Books",
  new_password: "New Password",
  confirm_new_password: "Confirm New Password",
  reset_password: "Reset Password",
  about: "About",
  details: "Details",
  reviews: "Reviews",
  read_more: "Read More",
  language: "Language",
  english: "English",
  marathi: "Marathi",
  add_to_cart: "Add To Cart",
  buy_now: "Buy Now",
  book_title: "Book Title",
  author: "Author",
  co_author: "Coauthor",
  edition_language: "Edition Language",
  data_publish: "Data Published",
  publisher: "Publisher",
  tags: "Tags",
  non_fiction: "Non-Fiction",
  self_help: "Self help",
  trending: "Trending",
  best_seller: "Bestseller",
  notification: "Notifications",
  cart: "Cart",
  price_details: "Price Details",
  all_good_news: "All Good News",
  total: "Total",
  more_like_this: "More Like This",
  proceed_to_buy: "Proceed to buy",
  all: "All",
  downloaded: "Downloaded",
  search: "Search",
  explore_more: "Explore More",
  edit_profile: "Edit Profile",
  log_out: "Log out",
  purchase_history: "Purchase History",
  my_preferances: "My Preferances",
  deactivate_account: "Deactivate Account",
  do_you_want_to_logout: "Do you want to log out?",
  cancel: "Cancel",
  change_image: "Change Image",
  save: "Save",
  name: "Name",
  email_ID: "Email Id",
  mobile_number: "Mobile No.",
  genres: "Genres",
  reset: "Reset",
  apply: "Apply",
  continue: "Continue",
  do_you_want_to_submit_quiz: "Do you want to submit this quiz?",
  do_you_want_to_quit: "Do you want to quit?",
  quit: "Quit",
  correct_answers: "Correct answers",
  wrong_answers: "Wrong answers",
  your_answers: "Your answers",
  do_you_want_to_deactivate_account:
    "Do You want to Premantly \nDeactivate or Delete Account?",
  deactivate: "Deactivate",
  post: "Post",
  videos: "Videos",
  subscription: "Subscription",
  read_now: "Read Now",
  forgot_content:
    "Don’t worry it happens. Please enter the\naddress associated with your account",
  signup: "Signup",
  create_account_here: "Create your account here...",
  full_name: "Full Name",
  mobile_no: "Mobile No",
  confirm_password: "Confirm Password",
  i_agree: "I agree ",
  terms: "Terms ",
  policies: " Policies",
  only_book_can_help_you: "Only Books Can Help You",
  intro_one_content:
    "Books can help you to increase your\nknowledge and become more successfully.",
  journey_within_pages: "Journeys within Pages",
  intro_two_content:
    "A great book should leave you with many\nexperiences and slightly exhausted at the end\n. you live several lives while reading.",
  friend_in_pages: "Friends in pages",
  intro_three_content:
    "A good book is the best of friends , the same\ntoday and forever.",
  no_books_found: "No Books Found",
  you_are_not_connected_to_internet:
    "You are not connected to Internet. Please read already downloaded books.",
  your_account_has_been_deactivated: "Your account has been deactivated",
  institue_not_allow_to_login: "institue is not allow to login",
  google_sign_in_failed: "Google Sign-in failed.",
  sign_in_cancel: "Sign in Cancelled",
  sign_in_progress: "In process",
  play_service_not_available: "Play Service not available",
  something_went_wrong: "Something went Wrong",
  please_enter_name: "Please enter your name",
  please_enter_email: "Please enter email",
  please_enter_valid_email: "Please enter valid email",
  please_enter_mobile_number: "Please enter mobile number",
  please_enter_valid_number: "Please enter valid mobile number",
  please_enter_password: "Please enter password",
  password_regex_text:
    "Password must be at least 8 characters long with 1 number,\n1 uppercase letter,1 lowercase letter and 1 special character.",
  please_enter_confirm_password: "Please enter confirm password",
  password_confirm_password_not_match:
    "Password and confirm password do not match",
  please_agree_term_conditions: "Please Agree To All Terms And Privacy Policy ",
  google_sign_up_failed: "Google Sign-up failed.",
  enter_confirm_password: "Enter your confirm password",
  new_password_confirm_password_not_match:
    "New password and confirm password does not match",
  password_reset_successfully: "Password reset successfully",
  set_new_password: "Set New Password ",
  enter_password_below: "Enter a new password below & we’ll log\nyou in asap!",
  otp_sent_successfully: "Otp sent successfully on your email",
  forget_password: "Forgot Password\n?",
  no_books_for_purchase: "No books are found for purchase",
  notification_not_found: "Notification not found",
  apply_successfully: "Apply successfully",
  thank_you_for_purchase: "Thank you for your purchase",
  review_description: "Please enter you review description",
  no_books_are_added_for_cart: "No books are added for cart",
};
