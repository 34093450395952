import { Modal } from "antd";
import React, { useContext } from "react";
import { STRINGS } from "../../constants";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../context";

const LogoutPopup = ({ isModal, setIsModal, logout }) => {
  const { user, profile, fetchProfile } = useContext(AuthContext);

  const { t } = useTranslation();

  return (
    <div>
      <Modal
        width={300}
        open={isModal}
        onOk={() => setIsModal(false)}
        onCancel={() => setIsModal(false)}
        destroyOnClose
        centered
        footer={null}
      >
        <div>
          <h2 className="text-center text-base font-medium">
            {t(STRINGS.do_you_want_to_logout)}
          </h2>
          <div className="flex items-center justify-center gap-2 mt-4">
            <button className="px-4 py-2 rounded-2xl text-sm text-[#8A8A8A] border border-[#00000014]">
              {t(STRINGS.cancel)}
            </button>
            <button
              onClick={logout}
              className="px-4 py-2 rounded-2xl text-sm text-[#fff] border bg-[#FE6B01]"
            >
              {t(STRINGS.log_out)}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default LogoutPopup;
