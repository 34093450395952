//PACKAGES
import axios from "axios";
// import localStorage from '@react-native-async-storage/async-storage';

//CONSTANT
import { STORAGE_KEY } from "../constants";

async function getHeaders() {
  const user = await localStorage.getItem(STORAGE_KEY.USER_DETAILS);
  if (user) {
    const userJson = JSON.parse(user);
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + userJson?.token ?? "",
    };
  } else {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
  }
}

async function getFormDataHeaders() {
  const user = await localStorage.getItem(STORAGE_KEY.USER_DETAILS);
  if (user) {
    const userJson = JSON.parse(user);
    return {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + userJson?.token ?? "",
    };
  } else {
    return {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    };
  }
}

export async function getRequest(url, params) {
  let headers = await getHeaders();

  console.log("=====================");
  console.log("URL", url);
  console.log("params", params);
  console.log("headers", headers);
  console.log("=====================");

  try {
    let config;

    if (params) {
      config = {
        method: "GET",
        url: url,
        params: params,
        headers: headers,
      };
    } else {
      config = {
        method: "GET",
        url: url,
        headers: headers,
      };
    }

    const response = await axios(config);
    return getResponse(response);
  } catch (err) {
    return getError(err);
  }
}

export async function postRequest(url, params) {
  let headers = await getHeaders();

  console.log("=====================");
  console.log("URL", url);
  console.log("params", params ? params : "");
  console.log("headers", headers);
  console.log("=====================");

  try {
    let config;

    if (params) {
      config = {
        method: "POST",
        url: url,
        data: params,
        headers: headers,
      };
    } else {
      config = {
        method: "POST",
        url: url,
        headers: headers,
      };
    }

    const response = await axios(config);
    return getResponse(response);
  } catch (err) {
    console.log(err);
    return getError(err);
  }
}

export async function putRequest(url, params, form) {
  let headers = "";
  if (form == "form") {
    headers = await getFormDataHeaders();
  } else {
    headers = await getHeaders();
  }

  console.log("=====================");
  console.log("URL", url);
  console.log("params", params);
  console.log("headers", headers);
  console.log("=====================");

  try {
    const config = {
      method: "PUT",
      url: url,
      data: params,
      headers: headers,
    };
    const response = await axios(config);
    return getResponse(response);
  } catch (err) {
    console.log(err);
    return getError(err);
  }
}

export async function deleteRequest(url, params) {
  let headers = await getHeaders();

  console.log("=====================");
  console.log("URL", url);
  console.log("params", params);
  console.log("headers", headers);
  console.log("=====================");

  try {
    const config = {
      method: "DELETE",
      url: url,
      data: params,
      headers: headers,
    };
    const response = await axios(config);
    return getResponse(response);
  } catch (err) {
    console.log(err);
    return getError(err);
  }
}

export async function postMultipartRequest(url, params) {
  let headers = await getFormDataHeaders();

  console.log("=====================");
  console.log("URL", url);
  console.log("params", params);
  console.log("headers", headers);
  console.log("=====================");

  try {
    let config;

    if (params) {
      config = {
        method: "post",
        url: url,
        data: params,
        headers: headers,
      };
    } else {
      config = {
        method: "post",
        url: url,
        headers: headers,
      };
    }

    const response = await axios(config);
    return getResponse(response);
  } catch (err) {
    console.log(err);
    return getError(err);
  }
}

export async function getMultipartRequest(url, params) {
  let headers = await getFormDataHeaders();

  console.log("=====================");
  console.log("URL", url);
  console.log("params", params);
  console.log("headers", headers);
  console.log("=====================");

  try {
    let config;

    if (params) {
      config = {
        method: "post",
        url: url,
        data: params,
        headers: headers,
      };
    } else {
      config = {
        method: "post",
        url: url,
        headers: headers,
      };
    }

    const response = await axios(config);
    return getResponse(response);
  } catch (err) {
    console.log(err);
    return getError(err);
  }
}

const getResponse = async (response) => {
  console.log("", JSON.stringify(response.data));
  if (response.status == 200 || response.status == 201) {
    if (response?.data?.status) {
      if (response?.data?.status == 200 || response?.data?.status == 201) {
        let result = {
          status: true,
          data: response?.data ?? null,
          error: response?.data?.message ?? "",
        };
        return result;
      } else {
        let result = {
          status: false,
          data: response?.data ?? null,
          error: response?.data?.message ?? "",
        };
        return result;
      }
    } else {
      console.log(JSON.stringify(response?.data));
      let result = {
        status: true,
        data: response?.data ?? null,
        error: response?.data?.message ?? "",
      };
      return result;
    }
  } else {
    console.log(response);
    let result = {
      status: false,
      data: response?.data ?? null,
      error: response?.data?.message ?? "Something went wrong",
    };
    return result;
  }
};

const getError = (error) => {
  console.log("error", error);
  console.log("error", error?.response);
  console.log("error", error?.response?.data);

  var message = "";
  var obj = null;
  if (error.response) {
    if (error.response.data) {
      obj = error.response.data;
      if (error.response.data.message) {
        message = error.response.data.message;
      } else {
        message = JSON.stringify(error.response.data.message);
      }
    } else {
      obj = error.response;
      message = "Something went wrong";
    }
  } else {
    obj = error;
    message = error.message;
  }

  let data = {
    status: false,
    data: obj,
    error: message,
    status_code: error?.response?.status ?? "",
  };
  return data;
};
