import React, { useState } from "react";
import AppButton from "../../components/button/AppButton";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { IMAGES } from "../../assets";
import { useLocation, useNavigate } from "react-router-dom";
import { SHOW_TOAST, STRINGS } from "../../constants";
import { useTranslation } from "react-i18next";
import { userResetPassword } from "../../api";
import * as Yup from "yup";
import IconTint from "react-icon-tint";

const NewPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const email = location?.state?.email;
  const [isLoading, setIsLoading] = useState(false);
  const [loginState, setLoginState] = useState({
    passwordHideShow: false,
    cpasswordHideShow: false,
  });
  const { t } = useTranslation();
  const initialValues = {
    email: email,
    password: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object({
    password: Yup.string().required(t(STRINGS?.please_enter_email)),
  });

  const onSubmit = async (values) => {
    await onResetPassword(values?.password);
    console.log(values, "onsubmit values");
  };

  async function onResetPassword(password) {
    const params = {
      emailId: email,
      password: password,
    };

    setIsLoading(true);
    const result = await userResetPassword(params);
    console.log(result, "set new password result");
    setIsLoading(false);

    console.log("RESET password result", result);

    if (result?.status) {
      SHOW_TOAST(t(STRINGS.password_reset_successfully), "success");

      setTimeout(() => {
        navigate("/login");
        // props.navigation.navigate(SCREENS.Login.name)
      }, 1000);
    } else {
      SHOW_TOAST(result?.error, "error");
    }
  }

  return (
    <div className="w-full">
      <div className="w-[100%] flex h-[100vh]">
        <div className="w-[50%] relative h-full  bg-[#FE6B010F] lg:block md:hidden sm:hidden hidden  ">
          {/* <AuthPageImage ImageUrl={Image} logo={Logo} /> */}
          <div className="flex flex-col items-center justify-between h-full">
            <img
              src={IMAGES?.logo}
              className="w-auto h-24 bottom-0 relative mt-12"
            />
            <img
              src={IMAGES?.reading_ledy}
              className="w-auto h-96 bottom-0 relative"
            />
          </div>
        </div>
        <div className="w-full   lg:w-[50%]  md:w-full  sm:w-full  px-4   sm:px-28  py-2 ">
          <button
            className="w-8 h-8 text-lg font-bold border-slate-400 border flex items-center justify-center rounded-full"
            onClick={() => navigate(-1)}
          >
            <img
              className="w-full h-full"
              alt="backarrow"
              src={IMAGES?.backIcon}
            />
          </button>
          <div className="h-full  flex flex-col justify-center items-center relative">
            <div className=" flex flex-col max-w-[345px]">
              <h2 className="py-1 font-semibold text-3xl">
                {t(STRINGS?.set_new_password)}
              </h2>
              <p className="py-2 text-paragraph pb-3">
                {t(STRINGS.enter_password_below)}
              </p>
              {/* <LoginForm /> */}

              <div className="w-full">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {({ resetForm }) => {
                    return (
                      <Form className="py-4">
                        <div className="relative mb-4">
                          <Field
                            type={
                              loginState?.passwordHideShow ? "text" : "password"
                            }
                            id="password"
                            name="password"
                            placeholder={t(STRINGS.new_password)}
                            className="form-control w-full px-2 py-2 rounded-[8px] text-sm font-normal bg-[#EDE7F557] outline-none"
                          />
                          <button
                            type="button"
                            onClick={() =>
                              setLoginState((prev) => ({
                                ...prev,
                                passwordHideShow: !loginState?.passwordHideShow,
                              }))
                            }
                            className="absolute top-0 bottom-0 mr-2 right-0 h-full outline-none"
                          >
                            {loginState?.passwordHideShow ? (
                              <IconTint
                                src={IMAGES?.showIcon}
                                color="#B0B0B0"
                                className="text-lg font-medium  w-5 h-auto "
                              />
                            ) : (
                              <IconTint
                                src={IMAGES?.hideIcon}
                                color="#B0B0B0"
                                className="text-lg font-medium  w-5 h-auto "
                              />
                            )}
                          </button>
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="text-red-500 formik-error text-sm"
                          />
                        </div>

                        <div className="relative mb-4">
                          <Field
                            type={
                              loginState?.cpasswordHideShow
                                ? "text"
                                : "password"
                            }
                            id="confirmPassword"
                            name="confirmPassword"
                            placeholder={t(STRINGS.confirm_new_password)}
                            className="form-control w-full px-2 py-2 rounded-[8px] text-sm font-normal bg-[#EDE7F557] outline-none"
                          />
                          <button
                            type="button"
                            onClick={() =>
                              setLoginState((prev) => ({
                                ...prev,
                                cpasswordHideShow:
                                  !loginState?.cpasswordHideShow,
                              }))
                            }
                            className="absolute top-0 bottom-0 mr-2 right-0 h-full outline-none"
                          >
                            {loginState?.cpasswordHideShow ? (
                              <IconTint
                                src={IMAGES?.showIcon}
                                color="#B0B0B0"
                                className="text-lg font-medium  w-5 h-auto "
                              />
                            ) : (
                              <IconTint
                                src={IMAGES?.hideIcon}
                                color="#B0B0B0"
                                className="text-lg font-medium  w-5 h-auto "
                              />
                            )}
                          </button>
                          <ErrorMessage
                            name="confirmPassword"
                            component="div"
                            className="text-red-500 formik-error text-sm"
                          />
                        </div>

                        <AppButton
                          btnText={t(STRINGS.reset_password)}
                          disabled={false}
                          className="bg-[#FE6B01] text-white w-full py-2 mt-4 rounded-2xl"
                          btnType={"Submit"}
                        />
                      </Form>
                    );
                  }}
                </Formik>

                {/* <ModalForgot state={loginState} setState={setLoginState} /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewPassword;
