import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../context";
import { getPreference, resetPreference, savePreference } from "../../../api";
import { SHOW_TOAST, STRINGS } from "../../../constants";

const MyPreference = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const [selectedType, setSelectedType] = useState(0);
  const [selectedItem, setSelectedItem] = useState([]);
  const [selectedAuthorItem, setSelectedAuthorItem] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [authorList, setAuthorList] = useState([]);
  const [genersList, setGenersList] = useState([]);

  useEffect(() => {
    onMyPreference();
  }, [selectedType]);

  async function onMyPreference() {
    const params = {
      preferncesOption: selectedType == "0" ? "genre" : "author",
      userId: user?.userInfo?._id,
    };

    setIsLoading(true);
    const result = await getPreference(params);
    setIsLoading(false);

    if (result?.status) {
      if (selectedType == 0) {
        const generResponse = result?.data?.data?.genreList ?? [];
        setGenersList(generResponse);

        const selectedList = generResponse.filter((e) => {
          return e?.is_selected == true;
        });

        const selectedGener = selectedList?.map((e) => {
          return e?.categoryName;
        });
        setSelectedItem(selectedGener);
      } else {
        const authorRes = result?.data?.data?.authorList ?? [];
        setAuthorList(authorRes);

        const selectedList = authorRes.filter((e) => {
          return e?.is_selected == true;
        });

        const selectedAuthor = selectedList?.map((e) => {
          return e?.authorName;
        });

        setSelectedAuthorItem(selectedAuthor);
      }
    } else {
      SHOW_TOAST(result?.error, "error");
    }
  }

  async function onApply() {
    let params = {
      userId: user?.userInfo?._id,
      isAuthor: selectedType == "0" ? false : true,
    };

    const array = selectedType == "0" ? selectedItem : selectedAuthorItem;

    try {
      setIsLoading(true);
      const result = await savePreference(params, array);
      setIsLoading(false);

      if (result?.status) {
        SHOW_TOAST(t(STRINGS.apply_successfully), "success");
      } else {
        SHOW_TOAST(result?.error, "error");
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function onResetPreference() {
    const params = {
      userId: user?.userInfo?._id,
    };

    setIsLoading(true);
    const result = await resetPreference(params);
    setIsLoading(false);

    console.log("RESET PRE", result);

    if (result?.status) {
      SHOW_TOAST("Reset successfully", "success");
      onMyPreference();
    } else {
      SHOW_TOAST(result?.error, "error");
    }
  }

  console.log(user, "user name in my preference");
  return (
    <div className="flex flex-col  items-center justify-between h-full">
      <div>
        <h2 className="text-base font-medium">{t(STRINGS.my_preferances)} </h2>
        <div className="flex items-center gap-2 my-2">
          <button
            onClick={() => setSelectedType(0)}
            className={`${
              selectedType == 0 ? "text-white bg-blue-600" : "text-slate-400 "
            } border rounded-lg px-4 py-2`}
          >
            <p> {t(STRINGS.genres)}</p>
          </button>
          <button
            onClick={() => setSelectedType(1)}
            className={`${
              selectedType == 1 ? "text-white bg-blue-600" : "text-slate-400 "
            } border rounded-lg px-4 py-2`}
          >
            <p> {t(STRINGS.author)}</p>
          </button>
        </div>

        <div>
          {selectedType == 0 && (
            <div>
              {genersList?.map((item, index) => {
                const isInclude = selectedItem.includes(item.categoryName);
                return (
                  <button
                    className={`${
                      isInclude
                        ? " text-orange-400 border-orange-400"
                        : "text-slate-500 border-slate-500"
                    } px-4 border py-2 rounded-lg m-1`}
                    onClick={() => {
                      // setSelectedItem(index)
                      if (isInclude) {
                        const removeArr = selectedItem.filter((filterItem) => {
                          return filterItem != item.categoryName;
                        });
                        setSelectedItem(removeArr);
                      } else {
                        const add = [...selectedItem];
                        add.push(item.categoryName);
                        setSelectedItem(add);
                      }
                    }}
                  >
                    {item?.categoryName}
                  </button>
                );
              })}
            </div>
          )}
        </div>

        <div>
          {selectedType == 1 && (
            <div>
              {authorList?.map((item, index) => {
                const isInclude = selectedAuthorItem.includes(item?.authorName);
                return (
                  <button
                    className={`${
                      isInclude
                        ? " text-orange-400 border-orange-400"
                        : "text-slate-500 border-slate-500"
                    } px-4 border py-2 rounded-lg m-1`}
                    onClick={() => {
                      // setSelectedItem(index)
                      if (isInclude) {
                        const removeArr = [...selectedAuthorItem].filter(
                          (filterItem) => filterItem != item?.authorName
                        );
                        setSelectedAuthorItem(removeArr);
                      } else {
                        const add = [...selectedAuthorItem];
                        add.push(item?.authorName);
                        setSelectedAuthorItem(add);
                      }
                    }}
                  >
                    {item?.authorName}
                  </button>
                );
              })}
            </div>
          )}
        </div>
      </div>
      <div className="flex items-center gap-2">
        <button
          onClick={() => {
            onResetPreference();
          }}
          className="px-4 py-2 border rounded-2xl"
        >
          {t(STRINGS.reset)}
        </button>
        <button
          onClick={() => {
            onApply();
          }}
          className="px-4 py-2 border rounded-2xl"
        >
          {" "}
          {t(STRINGS.apply)}
        </button>
      </div>
    </div>
  );
};

export default MyPreference;
