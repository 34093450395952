import React from "react";
import PropTypes from "prop-types";

const AppButton = ({
  btnText,
  disabled,
  onClick,
  className,
  btnType,
  rightIcon,
  leftIcon,
  props,
}) => {
  return (
    <button
      onClick={onClick}
      className={className}
      disabled={disabled}
      type={btnType}
      {...props}
    >
      <div className="flex items-center justify-center gap-1">
        {" "}
        {leftIcon}{" "}
        <span className="lg:text-base md:text-base font-medium">
          {btnText}{" "}
        </span>{" "}
        {rightIcon}
      </div>
    </button>
  );
};

AppButton.propTypes = {
  btnText: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  props: PropTypes.object,
  btnType: PropTypes.oneOf(["button", "Submit", "reset"]),
};

export default AppButton;
