import React from "react";
import { useTranslation } from "react-i18next";

const Subscription = (props) => {
  const { t } = useTranslation();
  return (
    <div>
      <h2 className="text-base font-medium">Subscription</h2>
    </div>
  );
};

export default Subscription;
