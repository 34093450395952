import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IMAGES } from "../../assets";
import AppButton from "../../components/button/AppButton";
import About from "./tabs/About";
import Details from "./tabs/Details";
import Reviews from "./tabs/Reviews";
import { AuthContext } from "../../context";
import { useTranslation } from "react-i18next";
import { STRINGS } from "../../constants";

const BookDetails = () => {
  const [activeTab, setActiveTab] = useState("About");
  const location = useLocation();
  const [bookData, setBookData] = useState(location?.state?.item ?? null);
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { profile } = useContext(AuthContext);

  const { user } = useContext(AuthContext);

  let selectedBookInfo = location?.state?.item ?? null;

  const [selectedType, setSelectedType] = useState(0);
  const [selectedLanguageButtonType, setSelectedLanguageButtonType] =
    useState(0);
  const [sliderValue1, setSliderValue1] = useState(0);
  const [expereienceInput, setExpereienceInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [rateCount, setRateCount] = useState(0);
  const [isDownloaded, setDownloaded] = useState(false);
  const [iosCacheImagePath, setIosCacheImagePath] = useState("");

  console.log(location, "location state data");
  console.log(bookData, "book details data");
  return (
    <div className="bg-white border p-4 rounded-2xl">
      <div class="grid grid-cols-3 divide-x">
        <div>
          <div className="my-4 flex items-center justify-between">
            <button
              className="w-8 h-8 text-lg font-bold border-slate-400 border flex items-center justify-center rounded-full"
              onClick={() => navigate(-1)}
            >
              <img
                className="w-full h-full"
                alt="backarrow"
                src={IMAGES?.backIcon}
              />
            </button>
          </div>

          <div className="w-full p-4 flex flex-col items-center justify-center gap-3">
            <img src={selectedBookInfo?.bookImage ?? IMAGES?.bookAboutIcon} />
            <div>
              <h2 className="text-lg font-bold text-center">
                {selectedBookInfo?.bookName}
              </h2>
              <p className="text-sm font-medium text-center">
                {selectedBookInfo?.authorName}
              </p>
            </div>
          </div>
        </div>
        <div className="pl-6 col-span-2">
          <div className="flex flex-col items-center divide-y w-full gap-2 relative">
            <div className="w-full">
              <div className="my-4 flex items-center justify-between w-full">
                <div className="flex items-center border rounded-2xl divide-x">
                  <button
                    onClick={() => setActiveTab("About")}
                    className={`${
                      activeTab === "About"
                        ? "bg-[#480D9B] text-white"
                        : "text-[#8A8A8A] "
                    } text-sm font-medium px-4 py-2 rounded-l-2xl`}
                  >
                    {t(STRINGS?.about)}
                  </button>

                  <button
                    onClick={() => setActiveTab("Details")}
                    className={`${
                      activeTab === "Details"
                        ? "bg-[#480D9B] text-white"
                        : "text-[#8A8A8A] "
                    } text-sm font-medium px-4 py-2 `}
                  >
                    {t(STRINGS?.details)}
                  </button>
                  <button
                    onClick={() => setActiveTab("Reviews")}
                    className={`${
                      activeTab === "Reviews"
                        ? "bg-[#480D9B] text-white"
                        : "text-[#8A8A8A] "
                    } text-sm font-medium px-4 py-2 rounded-r-2xl`}
                  >
                    {t(STRINGS?.reviews)}
                  </button>
                </div>
                <div>
                  <button className="w-8 h-8 text-lg font-bold border-slate-400 border flex items-center justify-center rounded-full">
                    <img
                      className="w-full h-full"
                      alt="backarrow"
                      src={IMAGES?.downloadIcon}
                    />
                  </button>
                </div>
              </div>

              {activeTab === "About" && <About data={selectedBookInfo} />}
              {activeTab === "Details" && <Details data={selectedBookInfo} />}
              {activeTab === "Reviews" && <Reviews data={selectedBookInfo} />}
            </div>
            <div className="w-full flex items-center justify-center mx-10">
              <AppButton
                onClick={() =>
                  navigate(`/readbook/${selectedBookInfo?.bookName}`, {
                    state: { bookPdfItem: selectedBookInfo },
                  })
                }
                btnText={t(STRINGS.read_now)}
                disabled={false}
                className="bg-[#FE6B01] text-white w-full py-2 mt-4 rounded-2xl mx-16"
                btnType={"button"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookDetails;
