import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../context";

const PurchaseHistory = (props) => {
  const { user } = useContext(AuthContext);
  const [error, setError] = useState(false);

  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [purchaseItem, setPurchaseItem] = useState([]);

  return (
    <div>
      <h2 className="text-base font-medium">Purchase History</h2>
    </div>
  );
};

export default PurchaseHistory;
