import React, { useContext, useState } from "react";
import { IMAGES } from "../assets";
import { Modal } from "antd";
import { AuthContext } from "../context";
import { useTranslation } from "react-i18next";
import { languageChange } from "../api";
import { SHOW_TOAST, STORAGE_KEY } from "../constants";
import { updateUserLanguage } from "../api/user";
import i18n from "../translation";

const Header = ({ notificationModal, setNotificationModal }) => {
  const [openLanguageModal, setOpenLanguageModal] = useState(false);
  const {
    profile,
    user,
    activeLanguage,
    setactiveLanguage,
    allLanguages,
    setAllLanguages,
  } = useContext(AuthContext);
  const { t } = useTranslation();
  console.log(allLanguages, "all languages");
  async function onSetLanguages() {
    try {
      const result = await languageChange();
      const language = await localStorage.getItem(STORAGE_KEY.ACTIVE_LANGUAGE);
      if (language) {
        setactiveLanguage(language);
      } else {
        setactiveLanguage("English");
      }
      if (result?.status) {
        setAllLanguages(result?.data?.data ?? []);
      } else {
        SHOW_TOAST(result?.error, "error");
      }
    } catch (e) {
      console.log(e);
    }
  }

  async function updateLanguage(lan) {
    console.log(lan, "language value");
    try {
      const param = {
        userId: user?.userInfo?._id,
        language: lan,
      };
      await updateUserLanguage(param);
      await localStorage.setItem(STORAGE_KEY.ACTIVE_LANGUAGE, lan);
      if (lan == "Marathi") {
        i18n.changeLanguage("mh");
        console.log("marathi");
      } else {
        i18n.changeLanguage("en");
      }
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <>
      <header
        className="sticky flex items-center top-0   bottom-0 h-16 z-20 "
        style={{ backdropFilter: "blur(5px)", background: "white" }}
      >
        <div className="w-full flex justify-between items-center mx-3 relative ">
          <div
            className="text-primary  sm:block md:block lg:hidden ms-2"
            //   onClick={onSidebarHandler}
          >
            <div className="icon hover:bg-gray-200 p-2 rounded-full">
              <svg
                aria-hidden="true"
                role="img"
                className="MuiBox-root css-1t9pz9x iconify iconify--eva"
                width="1.2em"
                height="1.2em"
                viewBox="0 0 24 24"
              >
                <circle cx="4" cy="12" r="1"></circle>
                <rect
                  width="14"
                  height="2"
                  x="7"
                  y="11"
                  rx=".94"
                  ry=".94"
                ></rect>
                <rect
                  width="18"
                  height="2"
                  x="3"
                  y="16"
                  rx=".94"
                  ry=".94"
                ></rect>
                <rect
                  width="18"
                  height="2"
                  x="3"
                  y="6"
                  rx=".94"
                  ry=".94"
                ></rect>
              </svg>
            </div>
          </div>

          <span></span>
          <div className="flex items-center justify-between float-right p-4">
            <div className="flex gap-2 items-center h-full">
              <div
                onClick={() => {
                  setOpenLanguageModal(true);
                }}
                className="h-10 w-10 flex items-center justify-center rounded-full border cursor-pointer"
              >
                <img src={IMAGES?.translatorIcon} className="w-full h-full" />
              </div>

              <div
                onClick={() => setNotificationModal(true)}
                className="h-10 w-10 rounded-full border cursor-pointer"
              >
                <img
                  src={IMAGES?.notificationIcon}
                  alt="userprofile"
                  className="h-full w-full rounded-ful"
                  style={{ borderRadius: "50%" }}
                />
                {/* <img src="" /> */}
              </div>
              {/* </Popover> */}
            </div>
          </div>
        </div>
      </header>
      {}
      <Modal
        open={openLanguageModal}
        onCancel={() => setOpenLanguageModal(false)}
        footer={null}
      >
        <div>
          <div
            onClick={() => {
              let lan = allLanguages?.[0]?.language;
              updateLanguage("English");
              setOpenLanguageModal(!openLanguageModal);
              setactiveLanguage("English");
              console.log("SELECTED LAN", "English");
            }}
          >
            <p>{allLanguages?.[0]?.language ?? "English"}</p>
          </div>
          <div
            onClick={() => {
              let lan = allLanguages?.[1]?.language;
              setOpenLanguageModal(!openLanguageModal);
              updateLanguage("Marathi");
              setactiveLanguage("Marathi");
              console.log("SELECTED LAN", "Marathi");
            }}
          >
            <p> {allLanguages?.[1]?.language ?? "Marathi"}</p>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Header;
