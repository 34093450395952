import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import PageRouter from "./components/Router";
import { AuthProvider } from "./context/authProvider";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <ToastContainer />
        <PageRouter />
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
