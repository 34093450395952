export const STRINGS = {
    login: 'login',
    please_enter_your_email_and_password: 'please_enter_your_email_and_password',
    email_id: 'email_id',
    password: 'password',
    forgot_password: 'forgot_password',
    or_login_with: 'or_login_with',
    google: 'google',
    facebook: 'facebook',
    dont_have_an_account: 'dont_have_an_account',
    sign_up: 'sign_up',
    have_an_account: 'have_an_account',
    log_in: 'log_in',
    submit: 'submit',
    home: 'home',
    myBooks: 'myBooks',
    quiz: 'quiz',
    account: 'account',
    trending_books: 'trending_books',
    newly_added_books: 'newly_added_books',
    other_books: 'other_books',
    new_password: 'new_password',
    confirm_new_password: 'confirm_new_password',
    reset_password: 'reset_password',
    about: 'about',
    details: 'details',
    reviews: 'reviews',
    read_more: "read_more",
    language: 'language',
    english: 'english',
    marathi: 'marathi',
    add_to_cart: 'add_to_cart',
    buy_now: 'buy_now',
    book_title: 'book_title',
    author: 'author',
    co_author: 'co_author',
    edition_language: 'edition_language',
    data_publish: 'data_publish',
    publisher: 'publisher',
    tags: 'tags',
    non_fiction: 'non_fiction',
    self_help: 'self_help',
    trending: 'trending',
    best_seller: 'best_seller',
    notification: 'notification',
    cart: 'cart',
    price_details: 'price_details',
    all_good_news: 'all_good_news',
    total: 'total',
    more_like_this: 'more_like_this',
    proceed_to_buy: 'proceed_to_buy',
    all: 'all',
    downloaded: 'downloaded',
    search: 'search',
    explore_more: 'explore_more',
    edit_profile: 'edit_profile',
    log_out: 'log_out',
    purchase_history: 'purchase_history',
    my_preferances: 'my_preferances',
    deactivate_account: 'deactivate_account',
    do_you_want_to_logout: 'do_you_want_to_logout',
    cancel: 'cancel',
    change_image: 'change_image',
    save: 'save',
    name: 'name',
    email_ID: 'email_ID',
    mobile_number: 'mobile_number',
    genres: 'genres',
    reset: 'reset',
    apply: 'apply',
    continue: 'continue',
    do_you_want_to_submit_quiz: 'do_you_want_to_submit_quiz',
    do_you_want_to_quit: 'do_you_want_to_quit',
    quit: 'quit',
    correct_answers: 'correct_answers',
    wrong_answers: 'wrong_answers',
    your_answers: 'your_answers',
    do_you_want_to_deactivate_account: 'do_you_want_to_deactivate_account',
    deactivate: 'deactivate',
    post: 'post',
    videos: 'videos',
    subscription: 'subscription',
    read_now: 'read_now',
    forgot_content: 'forgot_content',
    signup: 'signup',
    create_account_here: 'create_account_here',
    full_name: 'full_name',
    mobile_no: 'mobile_no',
    confirm_password: 'confirm_password',
    i_agree: 'i_agree',
    no_books_found: 'no_books_found',
    you_are_not_connected_to_internet: 'you_are_not_connected_to_internet',
    terms: 'terms',
    policies: 'policies',
    only_book_can_help_you: 'only_book_can_help_you',
    intro_one_content: 'intro_one_content',
    journey_within_pages: 'journey_within_pages',
    intro_two_content: 'intro_two_content',
    friend_in_pages: 'friend_in_pages',
    intro_three_content: 'intro_three_content',
    type: 'Type',
    category_wise_book: 'Category Books',
    your_account_has_been_deactivated:'Your account has been deactivated',
    institue_not_allow_to_login:'institue is not allow to login',
    google_sign_in_failed:'Google Sign-in failed.',
    sign_in_cancel:"Sign in Cancelled",
    sign_in_progress:'In process',
    play_service_not_available:"Play Service not available",
    something_went_wrong:"Something went Wrong",
    please_enter_name:'Please enter your name',
    please_enter_email:'Please enter email',
    please_enter_valid_email:'Please enter valid email',
    please_enter_mobile_number:'Please enter mobile number',
    please_enter_valid_number:'Please enter valid mobile number',
    please_enter_password:'Please enter password',
    password_regex_text:'Password must be at least 8 characters long with 1 number,\n1 uppercase letter,1 lowercase letter and 1 special character.',
    please_enter_confirm_password:'Please enter confirm password',
    password_confirm_password_not_match:'Password and confirm password do not match',
    please_agree_term_conditions:'Please Agree To All Terms And Privacy Policy ',
    google_sign_up_failed:"Google Sign-up failed.",
    enter_confirm_password:'Enter your confirm password',
    new_password_confirm_password_not_match:'New password and confirm password does not match',
    password_reset_successfully:'Password reset successfully',
    set_new_password:'Set New Password ',
    enter_password_below:'Enter a new password below & we’ll log\nyou in asap!',
    otp_sent_successfully:'Otp sent successfully on your email',
    forget_password:'Forgot Password\n?',
    no_books_for_purchase:'No books are found for purchase',
    notification_not_found:'Notification not found',
    apply_successfully:'Apply successfully',
    thank_you_for_purchase:'Thank you for your purchase',
    review_description:'Please enter you review description',
    no_books_are_added_for_cart:'No books are added for cart',
}
