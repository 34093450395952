import React, { useContext, useEffect, useState } from "react";
import { IMAGES } from "../../assets";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../context";
import { getCategoryList, getEbookList, getProgressOfBook } from "../../api";
import { SHOW_TOAST, STORAGE_KEY, STRINGS } from "../../constants";
import BookCard from "../../components/book/BookCard";
import BookListCard from "../../components/book/BookListCard";
import ProgressBar from "../../components/ProgressBar";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const { user } = useContext(AuthContext);

  const { t } = useTranslation();

  const [selectedListItem, setSelectedListItem] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [openLanguageModal, setOpenLanguageModal] = useState(false);
  const [categoryWiseBookList, setCategoryWiseBookList] = useState([]);
  const [newlyAddedBookList, setNewlyAddedBookList] = useState([]);
  const [otherBookList, setotherBookList] = useState([]);
  const [bookProgressItem, setBookProgressItem] = useState("");
  const [categoryItem, setCategoryItem] = useState([]);
  const [isInternetConnected, setIsInternetConnected] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState([]);

  //FOR SHOWING DOWNLOAD BOOK
  const [arrayDownload, setArrayDownload] = useState([]);
  const [userDownloadBook, setUserDownloadBook] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    Ebooklist();
  }, [selectedListItem]);

  useEffect(() => {
    // const listner = EventRegister.addEventListener("reload_home", () => {
    //   getDownloadedItem();
    // });
    // const unsubscribe = NetInfo.addEventListener((state) => {
    //   if (state.isConnected !== null) {
    //     setIsInternetConnected(state.isConnected);
    //     if (state.isConnected) {
    //       getBookProgress();
    //       Ebooklist();
    //     } else {
    //       setIsLoading(false);
    //     }
    //   }
    // });
    return () => {
      // EventRegister.removeEventListener(listner);
      // unsubscribe();
    };
  }, []);

  useEffect(() => {
    getBookProgress();
  }, []);

  useEffect(() => {
    categoryListing();
  }, []);

  useEffect(() => {
    getDownloadedItem();
  }, []);

  async function getBookProgress() {
    const params = {
      userId: user?.userInfo?._id,
    };

    const result = await getProgressOfBook(params);
    console.log(result, "book progess result");
    if (result?.status) {
      const response = result?.data?.eBookProgress;
      console.log(response, "response data progress");
      setBookProgressItem(response);
    } else {
      console.log(result?.error);
    }
  }

  async function categoryListing() {
    const result = await getCategoryList();
    const res = result?.data?.data ?? [];
    const allItem = { categoryName: "All" };
    const array = [allItem, ...res];
    setSelectedListItem(allItem);
    setCategoryItem(array);
  }

  async function getDownloadedItem() {
    const arrayStr = await localStorage.getItem(
      STORAGE_KEY.DOWNLOADED_BOOK_ITEM
    );
    if (arrayStr) {
      let array = JSON.parse(arrayStr);
      setArrayDownload(array);
    }
  }

  async function Ebooklist() {
    const params = {
      type: selectedListItem?.categoryName,
      userId: user?.userInfo?._id,
    };

    setIsLoading(true);
    const result = await getEbookList(params);
    setIsLoading(false);

    console.log(result, "result data categoruwise list");
    if (result?.status) {
      setUserDownloadBook(arrayDownload);
      setCategoryWiseBookList(result?.data?.data?.categoryWiseBookList);
      setNewlyAddedBookList(result?.data?.data?.newlyAddedBookList);
      setotherBookList(result?.data?.data?.otherBookList);
    } else {
      SHOW_TOAST(result?.error);
    }
  }

  console.log(bookProgressItem, "bookProgressItem");
  console.log(categoryItem, "categoryItem");
  console.log(categoryWiseBookList, "categoryWiseBookList");
  console.log(user, "user");
  console.log(arrayDownload, "arrayDownload");

  console.log(newlyAddedBookList, "Newly added book list ---------------");
  return (
    <div>
      <div className="progess card">
        {bookProgressItem == false ? null : (
          <div className="bg-[#FFE1CC5E] w-full rounded-xl px-4 py-2">
            <div className="flex items-center gap-4">
              <img
                className="h-32 w-auto"
                src={bookProgressItem?.bookImage ?? IMAGES?.richDadPoorIcon}
              />
              <div className="w-full">
                <div className="flex items-baseline justify-between">
                  <div>
                    <h2 className="text-lg font-bold">
                      {bookProgressItem?.bookName ?? "Rich Dad Poor Dad"}
                    </h2>
                    <span className="text-base font-medium">
                      {bookProgressItem?.authorName ?? "Robert T.Klyosaki"}
                    </span>
                  </div>
                  <div className="text-sm flex items-center justify-center gap-2">
                    <img className="h-4 " src={IMAGES.starIcon} />
                    <span>{bookProgressItem?.overallRating ?? "4.8"}</span>
                  </div>
                </div>

                <ProgressBar
                  color="#FE6B01"
                  percentage={bookProgressItem?.bookReadingStatus ?? "50"}
                  height="8px"
                />
                {/* <div className="w-full relative rounded-2xl p-1 mt-2 bg-[#FFFFFF]">
                  <div className="absolute top-0 left-0 w-1/2 rounded-2xl p-1 bg-[#FE6B01]"></div>
                </div> */}

                <span className="text-sm ">
                  {bookProgressItem?.bookReadingStatus ?? "0"}% Completed
                </span>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* tabs */}
      <div className="my-4 flex items-center gap-2">
        {categoryItem?.map((item, index) => {
          return (
            <button
              onClick={() => setSelectedListItem(item)}
              className={`${
                selectedListItem == item
                  ? "bg-[#480D9B] text-white"
                  : "text-slate-500 "
              } tab-btn py-2 px-5  text-sm border border-slate-400 rounded-2xl`}
            >
              {item?.categoryName}
            </button>
          );
        })}
      </div>

      <div className="grid grid-cols-3 gap-4">
        {!categoryWiseBookList?.type == "All" ||
        categoryWiseBookList?.length == "" ? null : (
          <BookListCard
            title={t(STRINGS.category_wise_book)}
            data={categoryWiseBookList}
          />
        )}

        {arrayDownload == "" ? null : (
          <BookListCard
            title={t(STRINGS?.trending_books)}
            data={arrayDownload}
          />
        )}

        {newlyAddedBookList.length > 0 && (
          <BookListCard
            title={t(STRINGS?.newly_added_books)}
            data={newlyAddedBookList}
          />
        )}

        {otherBookList == false ? null : (
          <BookListCard
            title={t(STRINGS.other_books)}
            data={otherBookList}
            onClick={() =>
              navigate("/otherbooks", {
                state: { otheBookItem: otherBookList },
              })
            }
            link={true}
          />
        )}
      </div>
    </div>
  );
};

export default Home;
