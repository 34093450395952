import React, { useContext, useEffect, useState } from "react";
import BookCard from "../../components/book/BookCard";
import { IMAGES } from "../../assets";
import { AuthContext } from "../../context";
import { useTranslation } from "react-i18next";
import { SHOW_TOAST, STRINGS } from "../../constants";
import { getSearchList } from "../../api";
import { addBookCart } from "../../api/user";
import { useNavigate } from "react-router-dom";
import { AutoComplete, Input } from "antd";

const ExploreMore = () => {
  const { user } = useContext(AuthContext);

  const { t } = useTranslation();

  const [search, setSearch] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchBookList, setSearchBookList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [autoData, setAutoData] = useState();
  const [onFocus, setOnfocus] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    onExploreList();
  }, []);

  async function onExploreList() {
    const params = {
      searchText: search,
    };

    setIsLoading(true);
    const result = await getSearchList(params);
    const Data = result?.data?.data?.eBookList?.map((ele) => {
      return {
        label: ele?.bookName,
        value: ele?.bookName,
      };
    });
    console.log(Data, "auto complete data");
    setAutoData(Data);
    setIsLoading(false);

    console.log("SEARCH", result);

    if (result?.status) {
      const res = result?.data?.data?.eBookList ?? [];
      setSearchBookList(res);
      setFilteredData(res);
    } else {
      SHOW_TOAST(result?.error, "error");
      setFilteredData([]);
    }
  }

  useEffect(() => {
    const filtered = filteredData.filter((item) => {
      return item.bookName.toLowerCase().startsWith(search.toLowerCase());
    });
    if (search === "") {
      return setSearchBookList(filteredData);
    }

    setSearchBookList(filtered);
  }, [search]);

  async function addToCart(item) {
    const params = {
      BookId: item?._id,
      bookName: item?.bookName,
      price: item?.price,
      authorName: item?.authorName,
      bookLanguage: item?.bookLanguage?.language,
      userId: user?.userInfo?._id,
    };

    setIsLoading(true);
    const result = await addBookCart(params);
    setIsLoading(false);

    console.log("addToCart", JSON.stringify(result));

    if (result?.status) {
      // props.navigation.navigate(SCREENS.AddToCart.name);
    } else {
      SHOW_TOAST(result?.error, "error");
    }
  }

  console.log(searchBookList, "searchBookList");

  const onSelect = (value) => {
    setSearch(value);
    console.log("onSelect", value);
  };
  const handleSearch = (value) => {
    setSearch(value);
    console.log(value, "searched values");
  };
  return (
    <div>
      <div className="flex items-center justify-between w-full">
        <h2 className="w-full mx-auto text-lg font-bold">
          {t(STRINGS.explore_more)}
        </h2>
        <div className="w-full">
          <AutoComplete
            onFocus={(e) => {
              console.log(e, "onfocus element");
              setOnfocus(true);
            }}
            options={autoData}
            onSelect={onSelect}
            onSearch={handleSearch}
            className=""
          >
            <Input className=""></Input>
          </AutoComplete>
          <input
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            className="w-full px-2 py-2 rounded-[8px] text-sm font-normal bg-[#e8e7e957] outline-none"
            placeholder={isVisible ? "" : t(STRINGS.search)}
          />
        </div>
      </div>

      <div className="mt-4">
        <div className="grid grid-cols-4 gap-1">
          {searchBookList?.map((item, idx) => (
            <BookCard
              key={idx}
              isCartBtn={false}
              isProgerss={false}
              img={item?.bookImage ?? IMAGES?.bookLogo}
              name={item?.bookName}
              price={"₹ " + item.price}
              rating={item?.overallRating}
              onClick={() =>
                navigate(`/aboutbook/${item?._id}`, { state: { item: item } })
              }
              type="vertical"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ExploreMore;
