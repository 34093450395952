import React from "react";
import { IMAGES } from "../../../assets";
import ReviewCard from "../../../components/ReviewCard";
import { Field, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import ProgressBar from "../../../components/ProgressBar";

const Reviews = ({ data }) => {
  const { t } = useTranslation();
  const onSubmit = (values) => {
    console.log(values, "values comment");
  };

  return (
    <div className="my-2">
      <div className="grid grid-cols-2 gap-2">
        <div>
          <h2 className="text-lg font-medium">Rate this book</h2>
          <p className="text-sm font-normal">Tell others what you think</p>
          <div className="flex gap-1">
            <img className="w-auto h-8" src={IMAGES?.starOrangeIcon} />
            <img className="w-auto h-8" src={IMAGES?.starOrangeIcon} />
            <img className="w-auto h-8" src={IMAGES?.starOrangeIcon} />
            <img className="w-auto h-8" src={IMAGES?.starOrangeIcon} />
            <img className="w-auto h-8" src={IMAGES?.starOrangeIcon} />
          </div>

          <Formik initialValues={{ comment: "" }} onSubmit={onSubmit}>
            <Form className="">
              <div className="flex flex-col">
                <label className="text-base font-medium">Write a review</label>
                <Field
                  type="text"
                  id="comment"
                  name="comment"
                  placeholder="Describe your experience (optional)"
                  className="w-full px-2 py-2 rounded-[8px] text-sm font-normal bg-[#EDE7F557] outline-none"
                />
                {/* <input
                className="w-full px-2 py-2 rounded-[8px] text-sm font-normal bg-[#EDE7F557] outline-none"
                placeholder="Describe your experience (optional)"
              /> */}

                <div className="flex items-end justify-end">
                  <button
                    type="submit"
                    className="px-4 py-1 border rounded-2xl bg-[#480D9B] text-white mt-2"
                  >
                    Post
                  </button>
                </div>
              </div>
            </Form>
          </Formik>
        </div>
        <div className="">
          <div className="flex">
            <div className="flex flex-col w-full">
              <h2 className="text-4xl font-normal">
                {" "}
                {(5 * data?.overallRating) / 100}
              </h2>
              <div className="flex items-center gap-1">
                <img className="h-4 w-auto" src={IMAGES?.starIcon} />
                <img className="h-4 w-auto" src={IMAGES?.starIcon} />
                <img className="h-4 w-auto" src={IMAGES?.starIcon} />
                <img className="h-4 w-auto" src={IMAGES?.starIcon} />
                <img className="h-4 w-auto" src={IMAGES?.starIcon} />
              </div>
              <span className="text-sm font-normal text-[#8A8A8A]">
                {data?.reviewUserCount}
              </span>
            </div>
            <div className="w-full">
              <div className="flex items-center justify-center gap-1 ">
                <p className="text-sm">5</p>
                <ProgressBar
                  percentage={data?.ratings?.[0]?.percentage}
                  height="6px"
                  color="#FE6B01"
                />
              </div>
              <div className="flex items-center justify-center gap-1 ">
                <p className="text-sm">4</p>
                <ProgressBar
                  percentage={data?.ratings?.[0]?.percentage}
                  height="6px"
                  color="#FE6B01"
                />
              </div>
              <div className="flex items-center justify-center gap-1 ">
                <p className="text-sm">3</p>
                <ProgressBar percentage="20" height="6px" color="#FE6B01" />
              </div>
              <div className="flex items-center justify-center gap-1 ">
                <p className="text-sm">2</p>
                <ProgressBar percentage="10" height="6px" color="#FE6B01" />
              </div>
              <div className="flex items-center justify-center gap-1 ">
                <p className="text-sm">1</p>
                <ProgressBar percentage="5" height="6px" color="#FE6B01" />
              </div>
            </div>
          </div>
          <div className=" max-h-60 overflow-y-scroll mt-2">
            <ReviewCard
              date="20-06-2023"
              name="Lorem ipsum"
              profile={IMAGES?.userIcon}
              comment="Lorem ipsum dolor sit amet, consectetur adipiscing elit.Nunc buygee vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos."
            />
            <ReviewCard
              date="20-06-2023"
              name="Lorem ipsum"
              profile={IMAGES?.userIcon}
              comment="Lorem ipsum dolor sit amet, consectetur adipiscing elit.Nunc buygee vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos."
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reviews;
