import React from "react";
import { IMAGES } from "../assets";

const ReviewCard = ({ date, name, profile, comment }) => {
  return (
    <div className="review-card p-2 rounded-2xl border mt-2 shadow-md mx-2">
      <div className="flex items-center gap-2">
        <div className="w-8 h-8 rounded-full bg-slate-400">
          <img src={profile} className="w-full h-full object-cover" />
        </div>
        <h2 className="text-lg font-medium">{name} </h2>
      </div>
      <div className="flex item gap-2">
        <div className="flex items-center gap-1">
          <img className="h-3 w-auto" src={IMAGES?.starIcon} />
          <img className="h-3 w-auto" src={IMAGES?.starIcon} />
          <img className="h-3 w-auto" src={IMAGES?.starIcon} />
          <img className="h-3 w-auto" src={IMAGES?.starIcon} />
          <img className="h-3 w-auto" src={IMAGES?.starIcon} />
        </div>
        <p className="text-sm font-base text-[#8A8A8A]">{date}</p>
      </div>
      <p className="mt-2 text-sm font-base text-[#8A8A8A]">{comment}</p>
    </div>
  );
};

export default ReviewCard;
