import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../assets";
import ProgressBar from "../ProgressBar";

const BookCard = ({
  img,
  key,
  name,
  price,
  rating,
  link,
  type,
  isProgerss,
  isCartBtn,
  onClick,
}) => {
  return (
    <>
      {type === "horizontal" ? (
        <div key={key} className="cursor-pointer" onClick={onClick}>
          <div className="border border-slate-200 rounded-md py-2 px-4 flex items-center gap-3 mt-2">
            <img className="h-20 w-auto" src={img} />
            <div className="flex items-baseline justify-between w-full">
              <div className="w-full">
                <h2 className="text-base font-medium">{name}</h2>

                <span className="text-sm font-medium text-[#545454]">
                  {price}
                </span>
                {isProgerss && <ProgressBar percentage="30" color="#480D9B" />}
                <span className="text-xs font-normal text-[#333333]">
                  50% Completed
                </span>
              </div>
              <div className="text-sm flex items-center justify-center gap-1">
                <img className="h-4 " src={IMAGES?.starIcon} />
                <span className="text-[#545454]">{rating}</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="cursor-pointer " onClick={onClick}>
          <div className=" relative border-slate-200 m-4 rounded-md px-2 py-4 flex flex-col items-center gap-3  bg-white">
            <div className=" min-h-32 w-auto h-32  rounded-xl border-2 ">
              <img
                className=" w-full h-full object-fill rounded-xl"
                src={img}
              />
            </div>
            <div className="flex flex-col items-start justify-center gap-2 relative w-full">
              <div className="w-full flex items-center gap-2">
                <h2
                  className="text-base font-bold truncate overflow-hidden"
                  title={name}
                >
                  {name}
                </h2>

                <div className="text-sm flex items-center justify-center gap-1">
                  <img className="h-4 " src={IMAGES?.starIcon} />
                  <span className="text-[#545454]">{rating}</span>
                </div>
              </div>
              {isCartBtn && <span className="text-sm font-normal">{name}</span>}
              <div className="flex items-center justify-between w-full">
                <span className="text-sm font-medium text-[#545454]">
                  {price}
                </span>
                {isCartBtn && (
                  <button className="text-sm text-white px-2 py-1 bg-[#FE6B01] rounded-md">
                    Add To cart
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BookCard;
