import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context";
import { useTranslation } from "react-i18next";
import { getNotificationList } from "../../api";
import { Cons } from "../../constants/Constants";
import { STRINGS } from "../../constants";
import moment from "moment";
import { IMAGES } from "../../assets";

const NotificationSidebar = ({ notificationModal, setNotificationModal }) => {
  const { profile } = useContext(AuthContext);

  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [instituteUserList, setInstituteUserList] = useState([]);
  const [regularUserList, setRegularUserList] = useState([]);

  useEffect(() => {
    getAllNotification();
  }, []);

  async function getAllNotification() {
    const params = {
      userType: profile?.userType == Cons.INSTITUTE ? "Institutes" : "Users",
    };

    setIsLoading(true);
    const result = await getNotificationList(params);
    setIsLoading(false);

    console.log("NOTIFICATION", result);

    if (result?.status) {
      if (profile?.userType == Cons.INSTITUTE ? "Institutes" : "All") {
        const instituteRes = result?.data?.data ?? [];
        setInstituteUserList(instituteRes);
        if (
          profile?.userType == Cons.INSTITUTE
            ? "Institutes"
            : "All" || result?.data?.data?.length == 0
        ) {
          setError(t(STRINGS.notification_not_found));
        }
      } else {
        // profile?.userType == "REGULAR_USER" ? "Users" : "All";
      }
      {
        const regularRes = result?.data?.data ?? [];

        setRegularUserList(regularRes);
        if (
          profile?.userType == "REGULAR_USER"
            ? "Users"
            : "All" || result?.data?.data?.length == 0
        ) {
          setError(t(STRINGS.notification_not_found));
        }
      }
    } else {
      setError(result?.error);
    }
  }

  return (
    <>
      {notificationModal ? (
        <div
          className="fixed right-0 w-[290px] bg-white border rounded-xl  h-[calc(100%-60px)] shadow-md "
          style={{ zIndex: "1000" }}
          // onMouseLeave={() => closeNotification()}
        >
          <div className="p-3 flex justify-between items-center">
            <div className="notification_title text-lg lg:text-xl md:text-xl  font-medium">
              {t(STRINGS.notification)}
            </div>
            <span
              className="cursor-pointer"
              onClick={() => setNotificationModal(false)}
            >
              <img src={IMAGES?.closeIcon} className="w-5 h-auto" />
            </span>
          </div>

          <div className="list-of_notification relative">
            {profile?.userType == Cons.INSTITUTE && (
              <div>
                {instituteUserList?.map((item, index) => {
                  return (
                    <div className="bg-[#F8F8F8] rounded-xl mb-2  py-2 px-3 mx-2">
                      <div className="flex items-start  w-full">
                        <div className="w-full">
                          <h2 className="text-base font-medium">
                            {item?.notificationTitle}
                          </h2>
                          <p className="text-sm font-normal ">
                            {item?.message}
                          </p>
                        </div>
                        <span className="text-xs text-[#8A8A8A]">
                          {moment(new Date(item?.created_at)).fromNow()}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default NotificationSidebar;
