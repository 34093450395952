import React from "react";
import BookCard from "./BookCard";
import { IMAGES } from "../../assets";
import { Link, useNavigate } from "react-router-dom";

const BookListCard = ({ title, data, link, onClick, isError }) => {
  const navigate = useNavigate();
  return (
    <div className="px-3 py-2 bg-white rounded-md ">
      <div className="flex items-center justify-between">
        <h2 className="text-base font-medium">{title}</h2>
        {link && (
          <div className="cursor-pointer" onClick={onClick}>
            <img
              className="w-6 h-auto"
              src={IMAGES?.forwardIcon}
              alt="rightArrow"
            />
          </div>
        )}
      </div>
      {isError ? (
        isError
      ) : (
        <>
          {data?.map((item, index) => {
            return (
              <BookCard
                isProgerss={true}
                img={item?.bookImage ?? IMAGES?.bookLogo}
                name={item?.bookName}
                price={"₹ " + item.price}
                rating={item?.overallRating}
                onClick={() =>
                  navigate(`/aboutbook/${item?._id}`, { state: { item: item } })
                }
                //  ink={'/aboutbook'} l
                type="horizontal"
              />
            );
          })}
        </>
      )}
    </div>
  );
};

export default BookListCard;
