import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IMAGES } from "../../assets";
import AppButton from "../../components/button/AppButton";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { REGEX, SHOW_TOAST, STRINGS } from "../../constants";
import * as Yup from "yup";
import { Alert } from "antd";
import { useTranslation } from "react-i18next";
import { userForgotPassword } from "../../api/user";

const ForgotPassoword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    email: "",
  };
  const [loginState, setLoginState] = useState({
    open: false,
    loader: false,
    error: "",
    passwordHideShow: false,
  });
  const validationSchema = Yup.object({
    email: Yup.string()
      .matches(REGEX.emailRegex, t(STRINGS?.please_enter_valid_email))
      .required(t(STRINGS?.please_enter_email)),
  });

  const onSubmit = async (values, { resetForm }) => {
    console.log(values, "values");
    await onForgotPassword(values?.email);
    // resetForm();
  };

  async function onForgotPassword(email) {
    const params = {
      emailId: email,
    };

    setIsLoading(true);
    const result = await userForgotPassword(params);
    console.log(result, "forgot password result");
    setIsLoading(false);

    if (result?.status) {
      SHOW_TOAST(t(STRINGS.otp_sent_successfully), "success");

      setTimeout(() => {
        navigate("/otp", { state: { email: email } });
        // props.navigation.navigate(SCREENS.Otp.name, {
        //     email: email
        // })
      }, 1000);
    } else {
      SHOW_TOAST(result?.error, "error");
    }
  }

  return (
    <div className="w-full  ">
      <div className="w-[100%]    flex h-[100vh]">
        <div className="w-[50%] relative h-full  bg-[#FE6B010F] lg:block md:hidden sm:hidden hidden  ">
          {/* <AuthPageImage ImageUrl={Image} logo={Logo} /> */}
          <div className="flex flex-col items-center justify-between h-full">
            <img
              src={IMAGES?.logo}
              className="w-auto h-24 bottom-0 relative mt-12"
            />
            <img
              src={IMAGES?.reading_ledy}
              className="w-auto h-96 bottom-0 relative"
            />
          </div>
        </div>
        <div className="w-full   lg:w-[50%]  md:w-full  sm:w-full  px-4   sm:px-28  py-2 ">
          <button
            className="w-8 h-8 text-lg font-bold border-slate-400 border flex items-center justify-center rounded-full"
            onClick={() => navigate(-1)}
          >
            <img
              className="w-full h-full"
              alt="backarrow"
              src={IMAGES?.backIcon}
            />
          </button>
          <div className="h-full  flex flex-col justify-center items-center relative">
            <div className=" flex flex-col max-w-[345px]">
              <h2 className="py-1 font-semibold text-3xl">
                {" "}
                {t(STRINGS.forget_password)}
              </h2>
              <p className="py-2 text-paragraph pb-3">
                {t(STRINGS.forgot_content)}
              </p>
              {/* <LoginForm /> */}

              <div className="w-full">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {({ resetForm }) => {
                    return (
                      <Form className="py-4">
                        {loginState?.error ? (
                          <div className="py-2">
                            <Alert
                              message={`${loginState?.error}`}
                              type="error"
                              showIcon
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="mb-4">
                          <Field
                            type="email"
                            id="email"
                            name="email"
                            placeholder={t(STRINGS.email_id)}
                            className="w-full px-2 py-2 rounded-[8px] text-sm font-normal bg-[#EDE7F557] outline-none"
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="text-red-500 formik-error text-sm"
                          />
                        </div>

                        <AppButton
                          btnText={t(STRINGS.submit)}
                          disabled={false}
                          className="bg-[#FE6B01] text-white w-full py-2 mt-4 rounded-2xl"
                          btnType={"Submit"}
                        />
                      </Form>
                    );
                  }}
                </Formik>

                {/* <ModalForgot state={loginState} setState={setLoginState} /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassoword;
