export const BASE_URL = "http://ebook.prometteur.in:5050/api/v1";

export const WEB_SERVICE = {
  sign_up: BASE_URL + "/app/userSingup",
  get_user_profile: BASE_URL + "/app/getUserInfo",
  login: BASE_URL + "/app/userLogin",
  forgot_password: BASE_URL + "/app/forgotPassword",
  otp_verify: BASE_URL + "/app/verifyOTP",
  reset_password: BASE_URL + "/app/resetPassword",
  log_out: BASE_URL + "/logout",
  add_review: BASE_URL + "/app/addReview",
  e_bookInfo: BASE_URL + "/app/e-bookInfo",
  get_e_booklist: BASE_URL + "/app/getEbookList",
  update_user_profile: BASE_URL + "/app/updateUser",
  user_status: BASE_URL + "/app/userStatus/",
  user_list: BASE_URL + "/app/userList",
  get_user_info: BASE_URL + "/app/getUserInfo",
  delete_user_info: BASE_URL + "/app/deleteUserInfo/",
  get_quiz_list: BASE_URL + "/app/getQuizList",
  add_book_to_cart: BASE_URL + "/addBookToCart",
  cart_book_list: BASE_URL + "/cartBookList?userId=",
  delete_book_cart: BASE_URL + "/deleteCartBook",
  update_purchase_book: BASE_URL + "/app/updatePurchaseBook",
  add_to_purchase_book: BASE_URL + "/app/addToPurchaseBook",
  get_purchase_history: BASE_URL + "/app/getPurchaseHistory",
  deactivate_user_account: BASE_URL + "/app/userStatus/",
  searchList: BASE_URL + "/app/exploreBookList",
  my_preference: BASE_URL + "/app/myPrefence",
  reset_my_preference: BASE_URL + "/app/resetMyPrefrence/",
  notification_list: BASE_URL + "/app/getNotificationList",
  update_book_pdf_status: BASE_URL + "/app/updateBookStatus/",
  get_more_item: BASE_URL + "/app/getMoreItem/",
  progress_book: BASE_URL + "/app/progressbook",
  my_book_list: BASE_URL + "/app/getMyBook/",
  google_login: BASE_URL + "/app/socialMediaSignUp",
  category_list: BASE_URL + "/categoryList",
  update_fcm_token: BASE_URL + "/app/updateDeviceToken/",
  change_language: BASE_URL + "/app/languageList",
};
