import React from "react";
import { STRINGS } from "../../../constants";
import { useTranslation } from "react-i18next";
import moment from "moment";

const Details = ({ data }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="my-2">
        <div className="grid grid-cols-2 gap-4 mx-10">
          <p className="text-[#8A8A8A] text-sm">{t(STRINGS.book_title)}</p>
          <h2 className="text-base font-medium"> {data?.bookName}</h2>
          <p className="text-[#8A8A8A] text-sm">{t(STRINGS.author)}</p>
          <h2 className="text-base font-medium"> {data?.authorName}</h2>
          <p className="text-[#8A8A8A] text-sm">{t(STRINGS.co_author)}</p>
          <h2 className="text-base font-medium"> {data?.co_authorName}</h2>
          <p className="text-[#8A8A8A] text-sm">
            {t(STRINGS.edition_language)}
          </p>
          <h2 className="text-base font-medium">
            {data?.bookLanguage?.language}
          </h2>
          <p className="text-[#8A8A8A] text-sm">{t(STRINGS.data_publish)}</p>
          <h2 className="text-base font-medium">
            {moment(data?.bookPublishDate).format("MMM, YYYY")}
          </h2>
          <p className="text-[#8A8A8A] text-sm">{t(STRINGS.publisher)}</p>
          <h2 className="text-base font-medium"> {data?.publisher}</h2>
          <p className="text-[#8A8A8A] text-sm"> {t(STRINGS.type)}</p>
          <div className="">
            <button className="text-[#480D9B] m-1 tab-btn py-1 px-3  text-sm border border-[#480D9B] rounded-2xl">
              {data?.bookType?.ebookType}
            </button>
            <button className="text-[#480D9B] m-1 tab-btn py-1 px-3  text-sm border border-[#480D9B] rounded-2xl">
              Self help
            </button>
            <button className="text-[#480D9B] m-1 tab-btn py-1 px-3  text-sm border border-[#480D9B] rounded-2xl">
              Trending
            </button>
            <button className="text-[#480D9B] m-1 tab-btn py-1 px-3  text-sm border border-[#480D9B] rounded-2xl">
              Bestseller
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Details;
