import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IMAGES } from "../../assets";
import { AuthContext } from "../../context";
import { updateBookPdfProgress } from "../../api";
import { STORAGE_KEY } from "../../constants";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ReadBook = () => {
  const location = useLocation();
  const navigate = useNavigate();
  console.log(location, "location data");
  const { user } = useContext(AuthContext);

  const bookPdfItem = location?.state?.bookPdfItem;

  const [pageCount, setPageCount] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState("");
  const [fileUrl, setFileUrl] = useState(null);

  useEffect(() => {
    getFileUrl();
  }, []);
  console.log(bookPdfItem, "");
  const pdfUrl = location?.state?.bookPdfItem?.bookPdf;
  console.log(
    location?.state?.bookPdfItem?.bookPdf,
    "location?.state?.bookPdf"
  );

  async function getFileUrl() {
    const arrayStr = await localStorage.getItem(
      STORAGE_KEY.DOWNLOADED_BOOK_ITEM
    );
    if (arrayStr) {
      let array = JSON.parse(arrayStr);
      if (array.some((e) => e._id == bookPdfItem._id)) {
        const index = array.findIndex((e) => e._id == bookPdfItem._id);
        if (index >= 0) {
          const book = array[index];
          setFileUrl(book.localPath);
          return;
        }
      }
    }
    setFileUrl(bookPdfItem.bookPdf);
  }

  async function onUpdatePdfProcress() {
    const params = {
      userId: user?.userInfo?._id,
      bookId: bookPdfItem?._id,
      totalPages: numPages,
      readPages: pageNumber,
    };

    const result = await updateBookPdfProgress(params);
    console.log(result, "book process data");
  }
  console.log(fileUrl, "fileUrl");

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
    onUpdatePdfProcress();
  }

  function nextPage() {
    changePage(1);
    onUpdatePdfProcress();
  }

  return (
    <div className="min-h-96 h-full bg-white rounded-2xl p-4">
      <div className="my-4 flex items-center justify-between">
        <button
          className="w-8 h-8 text-lg font-bold border-slate-400 border flex items-center justify-center rounded-full"
          onClick={() => navigate(-1)}
        >
          <img
            className="w-full h-full"
            alt="backarrow"
            src={IMAGES?.backIcon}
          />
        </button>
        <h2 className="mx-auto text-lg font-bold">{bookPdfItem?.bookName}</h2>
      </div>
      <div
        style={{ height: "400px" || "100vh", overflowY: "scroll" }}
        className="flex items-center justify-center"
      >
        <Document
          file={pdfUrl}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={(error) => console.error("Error loading PDF:", error)}
        >
          <Page pageNumber={pageNumber} />
        </Document>
      </div>
      <div>
        <button disabled={pageNumber <= 1} onClick={previousPage}>
          Previous
        </button>
        <span>
          {" "}
          Page {pageNumber} of {numPages}
        </span>
        <button disabled={pageNumber >= numPages} onClick={nextPage}>
          Next
        </button>
      </div>
    </div>
  );
};

export default ReadBook;
