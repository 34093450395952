// import Toast from "react-native-toast-message"
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export function SHOW_TOAST(message, type, visibilityTime) {
  console.log(message, type, "message and type in toast");
  switch (type) {
    case "success":
      toast.success(message, {
        position: "top-right",
        autoClose: visibilityTime || 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      break;

    case "info":
      toast.info(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      break;

    case "warning":
      toast.warn(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      break;
    case "error":
      toast.error(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      break;

    default:
      toast(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      break;
  }
}

// export function SHOW_SUCCESS_TOAST(message) {
//   Toast.show({
//     type: "success",
//     text1: message,
//     position: "bottom",
//   });
// }

const STORAGE_KEY = {
  USER_DETAILS: "user_details_json",
  IS_FIRST_TIME: "isFirstTime",
  DOWNLOADED_BOOK_ITEM: "DOWNLOADED_BOOK_ITEM",
  GOOGLE_USER: "GOOGLE_USER",
  IOS_CACHED_IMAGE: "IOS_CACHED_IMAGE",
};

export { STORAGE_KEY };
