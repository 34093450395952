import React, { useContext, useEffect, useRef, useState } from "react";
import { IMAGES } from "../../assets";
import { NavLink, useNavigate } from "react-router-dom";
import { Switch } from "antd";
import { AuthContext } from "../../context";
import { useTranslation } from "react-i18next";
import { deactive_user, logOut } from "../../api";
import { STORAGE_KEY, STRINGS } from "../../constants";
import { Cons } from "../../constants/Constants";
import PurchaseHistory from "./tabs/PurchaseHistory";
import Subscription from "./tabs/Subscription";
import MyPreference from "./tabs/MyPreference";
import LogoutPopup from "../../components/modal/LogoutPopup";
import EditProfile from "../../components/modal/EditProfile";

const Account = () => {
  const { user, profile, fetchProfile } = useContext(AuthContext);
  const { t } = useTranslation();
  const [activeLink, setActiveLink] = useState(t(STRINGS.subscription));
  const [isEnabled, setIsEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [editPopup, setEditPopup] = useState(false);
  const navigate = useNavigate();
  const isVisibleDeactivate = useRef();
  const toggleSwitch = (e) => {
    console.log(e, "eeeeeeeeeeeeeeeeeeeee");
    // isVisibleDeactivate?.current?.open();
    setIsSwitchOn(e);
    setIsEnabled((prev) => !prev);
  };

  const data = [
    profile?.userType == Cons?.INSTITUTE && {
      title: t(STRINGS.subscription),
      image: IMAGES.nextLogo,
    },
    { title: t(STRINGS.purchase_history), image: IMAGES.nextLogo },
    { title: t(STRINGS.my_preferances), image: IMAGES.nextLogo },
    {
      title: t(STRINGS.deactivate_account),
      switch: true,
      key: "deactivate account",
    },
  ];

  useEffect(() => {
    getProfile();

    // const listner = EventRegister.addEventListener("on_profile_update", () => {
    //   getProfile();
    // });

    return () => {
      // EventRegister.removeEventListener(listner)
    };
  }, []);

  async function getProfile() {
    const result = await fetchProfile();
  }

  async function onUserLogout() {
    const params = {
      _id: user?.userInfo?._id,
    };

    setIsLoading(true);
    const result = await logOut(params);
    localStorage.removeItem("user_details_json");
    setIsLoading(false);

    console.log("LOGOUT", result);

    await localStorage.clear();
    if (STORAGE_KEY.IS_FIRST_TIME) {
      localStorage.setItem(STORAGE_KEY.IS_FIRST_TIME, "1");
    }

    setTimeout(() => {
      setIsLoading(false);
      // props.navigation.dispatch(
      //   CommonActions.reset({
      //     index: 0,
      //     routes: [
      //       {
      //         name: SCREENS.Login.name,
      //       },
      //     ],
      //   })
      // );
      navigate("/login");
    }, 500);
  }

  async function onDeactivate() {
    const params = {
      _id: user?.userInfo?._id,
      activeStatus: "false",
    };

    setIsLoading(true);
    const result = await deactive_user(params);
    setIsLoading(false);

    console.log("DEACTIVE", result);

    if (result?.status) {
      navigate("/login");
      // props.navigation.dispatch(CommonActions.reset({
      //     index: 0,
      //     routes: [{
      //         name: SCREENS.Login.name
      //     }]
      // }))
    }
  }

  return (
    <div>
      <div className="grid grid-cols-5 gap-4">
        <div className="p-4 shadow-md col-span-3 rounded-2xl bg-white">
          <div className="shadow-md p-4 rounded-2xl">
            <div className="flex items-center gap-2">
              <div className="w-16 h-16 rounded-full bg-slate-400">
                <img
                  className="w-full h-full object-fill"
                  src={profile?.userImage ?? IMAGES?.userIcon}
                />
              </div>
              <div>
                <h2 className="text-lg font-medium"> {profile?.fullName}</h2>
                <p className="text-sm font-base text-[#8A8A8A]">
                  {profile?.emailId}
                </p>
              </div>
            </div>

            <div className="flex items-center gap-2 justify-between mt-4">
              <button
                onClick={() => setEditPopup(true)}
                className="px-4 py-2 rounded-2xl border w-full border-[#0000000F] text-[#8A8A8A]"
              >
                {t(STRINGS.edit_profile)}
              </button>
              <button
                onClick={() => setIsModal(true)}
                className="px-4 py-2 rounded-2xl border w-full border-[#FE6B01] text-[#FE6B01]"
              >
                {t(STRINGS.log_out)}
              </button>
            </div>
          </div>
          <div className="mt-4">
            <div className="text-[#545454]">
              {data?.map((item, index) => {
                return (
                  <button
                    onClick={() =>
                      setActiveLink(
                        item?.title === t(STRINGS.deactivate_account)
                          ? ""
                          : item?.title
                      )
                    }
                    className={` ${
                      activeLink === item?.title ? "text-[#FE6B01]" : " "
                    } flex items-center justify-between w-full px-1 py-2`}
                  >
                    <p>{item?.title}</p>
                    {item?.switch ? (
                      <Switch
                        onChange={(e) => toggleSwitch(e)}
                        defaultValue={isSwitchOn}
                        className="border"
                        defaultChecked={false}
                      />
                    ) : (
                      <img className="h-4 w-auto" src={item?.image} />
                    )}
                  </button>
                );
              })}
            </div>
          </div>
        </div>
        <div className="p-4 shadow-md rounded-2xl bg-white col-span-2">
          {activeLink === t(STRINGS.my_preferances) && <MyPreference />}
          {activeLink === t(STRINGS.purchase_history) && <PurchaseHistory />}
          {activeLink === t(STRINGS.subscription) && <Subscription data="" />}
        </div>
      </div>
      <LogoutPopup
        isModal={isModal}
        setIsModal={setIsModal}
        logout={onUserLogout}
      />

      <LogoutPopup
        title={t(STRINGS.do_you_want_to_deactivate_account)}
        cancel={t(STRINGS.cancel)}
        deactivate={t(STRINGS.deactivate)}
        open={isSwitchOn}
        onCancel={() => {
          setIsSwitchOn(false);
        }}
        onDeactivate={() => {
          setIsSwitchOn(false);
          onDeactivate();
        }}
      />
      <EditProfile editPopup={editPopup} setEditPopup={setEditPopup} />
    </div>
  );
};

export default Account;
