import { Drawer } from "antd";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { IMAGES } from "../assets";
import IconTint from "react-icon-tint";
import { STRINGS } from "../constants";
import { useTranslation } from "react-i18next";

const Sidebar = () => {
  const { t } = useTranslation();

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const nav = [
    {
      name: t(STRINGS?.home),
      path: "/home",
      icon: IMAGES?.homeIcon,
    },
    {
      name: t(STRINGS?.myBooks),
      path: "/mybook",
      icon: IMAGES?.bookIcon,
    },
    {
      name: "Explore More",
      path: "/exploremore",
      icon: IMAGES?.searchLogo,
    },
    {
      name: t(STRINGS.quiz),
      path: "/quiz",
      icon: IMAGES?.quizIcon,
    },
    {
      name: t(STRINGS.account),
      path: "/account",
      icon: IMAGES?.userIcon,
    },
    // {
    //   name: "Setting",
    //   path: "/setting",
    //   //   icon: faCog,
    //   suboption: [
    //     {
    //       name: "setting",
    //       path: "/sub",
    //     },
    //   ],
    // },
  ];

  return (
    <>
      <div
        className={`hidden lg:block w-[280px] ${
          isSidebarOpen ? "block" : "hidden"
        }`}
      >
        <div className="fixed w-[280px]  top-0 bg-white bottom-0 h-full">
          <div className="px-4">
            <div className="logo mx-auto flex justify-center items-center w-full">
              <img src={IMAGES?.logo} alt="App_logo" className="h-28 w-auto" />
            </div>
            <nav className=" sidebar-panel_list mt-8 flex flex-col justify-between">
              <ul className="relative">
                {nav?.map((ele, idx) => (
                  <li key={idx}>
                    <NavLink to={ele?.path}>
                      <div className="group p-3 rounded-full hover:bg-[#fe6b01] text-[#8A8A8A] mb-2 hover:text-white cursor-pointer transition-all ease-in">
                        <div className="flex items-center">
                          {ele.icon === true ? (
                            <div style={{ transform: "rotate(79deg)" }}>
                              <svg
                                stroke="currentColor"
                                fill="none"
                                stroke-width="2"
                                viewBox="0 0 24 24"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                height="20px"
                                width="20px"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                ></path>
                                <path d="M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>
                                <path d="M12 7a5 5 0 1 0 5 5"></path>
                                <path d="M13 3.055a9 9 0 1 0 7.941 7.945"></path>
                                <path d="M15 6v3h3l3 -3h-3v-3z"></path>
                                <path d="M15 9l-3 3"></path>
                              </svg>
                            </div>
                          ) : (
                            <IconTint
                              src={ele?.icon}
                              className="h-6 w-auto group-hover:text-[#8A8A8A] text-[#fff]   fill-current"
                              // color="#fff"
                            />
                            // <img src={ele?.icon} className="h-6 w-auto" />
                            // <FontAwesomeIcon icon={ele?.icon} />
                          )}

                          {/* <Icon icon={ele?.icon} color="red" classname="text-rose-300"/> */}
                          {/* {ele?.icon} */}
                          <span className="text-base font-medium  ml-4">
                            {ele?.name}
                          </span>
                        </div>
                      </div>
                    </NavLink>
                    {ele?.suboptions ? (
                      <ul className="pl-4">
                        {" "}
                        {/* Adjust the padding as needed */}
                        {ele.suboptions.map((subopt, subIdx) => (
                          <li key={subIdx}>
                            <NavLink to={subopt.path}>
                              <div className="p-2 rounded-full bg-gray-200  text-[#8A8A8A] hover:text-white cursor-pointer transition-all ease-in ml-2">
                                {subopt.name}
                              </div>
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    ) : null}
                  </li>
                ))}
              </ul>
              {/* <div className="absolute bottom-6   p-3 rounded-full text-[#8A8A8A] cursor-pointer mb-2">
              <div
                className="flex items-center "
                onClick={() => logoutHandler()}
              >
                <FontAwesomeIcon
                  className="text-[#18605D]"
                  icon={faSignOutAlt}
                />
                <span className="text-base font-medium ml-4 text-[#18605D]">
                  Logout
                </span>
              </div>
            </div> */}
            </nav>
          </div>
        </div>
      </div>

      <Drawer
        open={isSidebarOpen}
        placement={"left"}
        closable={false}
        width={280}
        // onClose={onClose}
        key={"left"}
      >
        <div className="w-full ">
          <div className="px-4 ">
            <div className="logo mx-auto flex justify-center items-center w-full">
              <img
                // src={logo}
                alt="App_logo"
                className="h-22 w-auto"
                style={{ width: "120px" }}
              />
            </div>
            <nav className=" sidebar-panel_list mt-4 flex flex-col justify-between">
              <ul className="relative">
                {nav?.map((ele, idx) => (
                  <li key={idx}>
                    <NavLink to={ele?.path}>
                      <div className=" p-3 rounded-full hover:bg-[#000]  text-[#8A8A8A] mb-2 hover:text-white cursor-pointer transition-all ease-in">
                        <div className="flex items-center">
                          {ele.icon === true ? (
                            <div style={{ transform: "rotate(79deg)" }}>
                              <svg
                                stroke="currentColor"
                                fill="none"
                                stroke-width="2"
                                viewBox="0 0 24 24"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                height="20px"
                                width="20px"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                ></path>
                                <path d="M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>
                                <path d="M12 7a5 5 0 1 0 5 5"></path>
                                <path d="M13 3.055a9 9 0 1 0 7.941 7.945"></path>
                                <path d="M15 6v3h3l3 -3h-3v-3z"></path>
                                <path d="M15 9l-3 3"></path>
                              </svg>
                            </div>
                          ) : (
                            ""
                            // <FontAwesomeIcon icon={ele?.icon} />
                          )}

                          <span className="text-base font-medium  ml-4">
                            {ele?.name}
                          </span>
                        </div>
                      </div>
                    </NavLink>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default Sidebar;
