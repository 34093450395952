export const IMAGES = {
  logo: require("./images/logo.png"),
  loginIcon: require("./images/loginIcon.png"),
  hideIcon: require("./images/hideIcon.png"),
  googleIcon: require("./images/googleIcon.png"),
  facebookIcon: require("./images/facebookIcon.png"),
  backIcon: require("./images/backIcon.png"),
  showIcon: require("./images/showIcon.png"),
  introduction2: require("./images/introduction22.png"),
  introduction1: require("./images/introduction1.png"),
  introduction3: require("./images/introduction3.png"),
  homeIcon: require("./images/ic_home.png"),
  bookIcon: require("./images/ic_book.png"),
  quizIcon: require("./images/ic_idea.png"),
  userIcon: require("./images/ic_user.png"),
  searchIcon: require("./images/ic_search.png"),
  translatorIcon: require("./images/ic_translator.png"),
  notificationIcon: require("./images/ic_notification.png"),
  orderIcon: require("./images/ic_order.png"),
  starIcon: require("./images/ic_star.png"),
  forwardIcon: require("./images/ic_forward.png"),
  nextIcon: require("./images/ic_next.png"),
  deleteIcon: require("./images/ic_delete.png"),
  upArrowIcon: require("./images/ic_arrow_up.png"),
  starOrangeIcon: require("./images/ic_star_orange.png"),
  cancelIcon: require("./images/ic_cancel.png"),
  errorIcon: require("./images/ic_error.png"),
  successIcon: require("./images/ic_success.png"),
  searchLogo: require("./images/ic_search_logo.png"),
  editIcon: require("./images/ic_edit.png"),
  nextLogo: require("./images/next_logo.png"),
  roundIcon: require("./images/ic_round.png"),
  closeIcon: require("./images/ic_close.png"),
  downloadIcon: require("./images/ic_download.png"),
  bookLogo: require("./images/book.png"),
  richDadPoorIcon: require("./images/ic_rich_dad_poor.png"),
  allGoodNewsIcon: require("./images/ic_all_good_news.png"),
  bookAboutIcon: require("./images/ic_book_about.png"),
  rightIcon: require("./images/ic_right.png"),
  radioCorrectIcon: require("./images/ic_radio_correct.png"),
  radioButtonIcon: require("./images/ic_radio_button.png"),
  reading_ledy: require("./images/reading_ledy.png"),
};

export const COLORS = {
  primary: "#480D9B",
  white: "#FFFFFF",
  black: "#000000",
  color_8A8A8A: "#8A8A8A",
  color_B0B0B0: "#B0B0B0",
  color_EDE7F557: "#EDE7F557",
  color_FE6B01: "#FE6B01",
  color_0034EB: "#0034EB",
  color_E6E6E6: "#E6E6E6",
  color_292B38: "#292B38",
  color_4D506C: "#4D506C",
  orange: "#FE6B01",
  cream: "#FFF0E6",
  textBlack: "#333333",
  gray: "#545454",
  blue: "#480D9B",
  borderColor: "#E7E7E7",
  darkBlack: "#030303",
  lightGray: "#F8F8F8",
  green: "#52BB00",
  red: "#E51B00",
};
