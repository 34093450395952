import { Modal } from "antd";
import React, { useContext, useState } from "react";
import { AuthContext } from "../../context";
import { useTranslation } from "react-i18next";
import { REGEX, SHOW_TOAST, STRINGS } from "../../constants";
import { updateUserProfile } from "../../api";
import AppButton from "../button/AppButton";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";

const EditProfile = ({ editPopup, setEditPopup }) => {
  const { user, setProfile, profile } = useContext(AuthContext);

  console.log("PROFILE", profile);

  const { t } = useTranslation();

  const [name, setName] = useState(profile?.fullName);
  const [email, setEmail] = useState(profile?.emailId);
  const [mobileNumber, setMobileNumber] = useState(
    profile?.mobileNo?.toString()
  );
  const [pickerResponse, setPickerResponse] = useState("");
  const [fileName, setfileName] = useState("");

  const validationSchema = Yup.object({
    emailId: Yup.string()
      .matches(REGEX.emailRegex, "Invalid email address")
      .required("phone number is required"),
    phoneNumber: Yup.string().required("Email is required"),
    name: Yup.string().required("name is required"),
  });

  const onSubmit = (value) => {
    console.log(value, "submit values");
    updateProfile(value.name, value.phoneNumber, value.emailId);
  };
  async function updateProfile(name, mobileNumber, emailId) {
    var photo = "";

    if (pickerResponse != "") {
      photo = {
        uri: pickerResponse,
        type: "image/jpeg",
        name: fileName,
      };
    } else {
      photo = pickerResponse;
    }

    const params = {
      _id: profile._id,
      fullName: name,
      userImage: photo,
    };

    const formData = new FormData();
    formData.append("fullName", name);
    if (photo != "") {
      formData.append("userImage", photo);
    }

    formData.append("mobileNo", mobileNumber);

    const result = await updateUserProfile(formData, profile?._id);

    if (result?.status) {
      setProfile(result?.data?.body?.userDetail);
      SHOW_TOAST("Profile updated successfully", "success");
      // EventRegister.emit('on_profile_update')
      // props.onCancel()
    } else {
      SHOW_TOAST(result?.error, "error");
    }
  }

  console.log(pickerResponse, "picker response");
  return (
    <div>
      <Modal
        width={400}
        open={editPopup}
        onOk={() => setEditPopup(false)}
        onCancel={() => setEditPopup(false)}
        destroyOnClose
        centered
        footer={null}
      >
        <div>
          <h2 className="text-base font-medium text-center">
            {" "}
            {t(STRINGS.edit_profile)}
          </h2>
          <div className="mt-2">
            <div className="flex items-center justify-center">
              <div className="h-12 w-12 rounded-full bg-slate-300 flex items-center justify-center text-center outline-none">
                <img
                  src={
                    profile?.userImage
                      ? pickerResponse == ""
                        ? profile?.userImage
                        : pickerResponse
                      : pickerResponse
                  }
                  alt="profile"
                  className="w-full h-full object-fill outline-none "
                />
              </div>
            </div>
            <div className="flex items-center justify-center mt-3">
              <label
                className="cursor pointer px-4 py-2 border rounded-2xl text-sm text-center"
                htmlFor="image"
              >
                {t(STRINGS.change_image)}{" "}
              </label>
              <input
                onChange={(e) => setPickerResponse(e.target.files[0])}
                className="hidden cursor-pointer"
                id="image"
                type="file"
                accept="image/*"
              />
            </div>
          </div>
          <div>
            <Formik
              initialValues={{
                name: profile?.fullName ?? "",
                emailId: profile?.emailId ?? "",
                phoneNumber: profile?.mobileNo?.toString() ?? "",
              }}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              <Form className="py-4">
                <div className="mb-4">
                  <label>{t(STRINGS.name)}</label>
                  <Field
                    type="text"
                    id="name"
                    name="name"
                    placeholder={t(STRINGS.name)}
                    className="w-full px-2 py-2 rounded-[8px] text-sm font-normal bg-[#EDE7F557] outline-none"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-red-500 formik-error text-sm"
                  />
                </div>
                <div className="mb-4">
                  <label> {t(STRINGS.email_ID)}</label>
                  <Field
                    type="email"
                    id="eamilId"
                    name="emailId"
                    placeholder={t(STRINGS.email_ID)}
                    className="w-full px-2 py-2 rounded-[8px] text-sm font-normal bg-[#EDE7F557] outline-none"
                  />
                  <ErrorMessage
                    name="emailId"
                    component="div"
                    className="text-red-500 formik-error text-sm"
                  />
                </div>
                <div className="mb-4">
                  <label>{t(STRINGS.mobile_number)}</label>
                  <Field
                    type="text"
                    id="phoneNumber"
                    name="phoneNumber"
                    placeholder={t(STRINGS.mobile_number)}
                    className="w-full px-2 py-2 rounded-[8px] text-sm font-normal bg-[#EDE7F557] outline-none"
                  />
                  <ErrorMessage
                    name="phoneNumber"
                    component="div"
                    className="text-red-500 formik-error text-sm"
                  />
                </div>

                <div className="flex items-center justify-center gap-2">
                  <AppButton
                    onClick={() => setEditPopup(false)}
                    btnText={`Cancel `}
                    disabled={false}
                    className="border  w-full py-2 mt-4 rounded-2xl"
                    btnType={"button"}
                  />
                  <AppButton
                    btnText={`Save `}
                    disabled={false}
                    className="bg-[#FE6B01] text-white w-full py-2 mt-4 rounded-2xl"
                    btnType={"Submit"}
                  />
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default EditProfile;
