export default {
  please_enter_your_email_and_password:
    "कृपया तुमचा ईमेल आयडी आणि पासवर्ड टाका",
  login: "लॉगिन करा",
  email_id: "ई - मेल आयडी",
  password: "पासवर्ड",
  forgot_password: "पासवर्ड विसरलात?",
  or_login_with: "किंवा यासह लॉग इन करा...",
  google: "Google",
  facebook: "फेसबुक",
  dont_have_an_account: `खाते नाही?  `,
  sign_up: "साइन अप करा",
  have_an_account: "खाते आहे का?  ",
  log_in: "लॉग इन करा",
  submit: "प्रस्तुत करणे",
  home: "मुख्यपृष्ठ",
  myBooks: "माझी पुस्तके",
  quiz: "प्रश्नमंजुषा",
  account: "खाते",
  trending_books: "ट्रेंडिंग पुस्तके",
  newly_added_books: "नवीन जोडलेली पुस्तके ",
  other_books: "इतर पुस्तके",
  new_password: "नवीन पासवर्ड",
  confirm_new_password: "नवीन परवलीच्या शब्दाची खात्री करा",
  reset_password: "पासवर्ड रीसेट करा",
  about: "बद्दल",
  details: "तपशील",
  reviews: "पुनरावलोकने",
  read_more: "पुढे वाचा",
  language: "इंग्रजी",
  english: "इंग्रजी",
  marathi: "मराठी",
  add_to_cart: "कार्टमध्ये जोडा",
  buy_now: "आता खरेदी करा",
  book_title: "पुस्तकाचे शीर्षक",
  author: "लेखक",
  co_author: "सहलेखक",
  edition_language: "संस्करण भाषा",
  data_publish: "डेटा प्रकाशित",
  publisher: "प्रकाशक",
  tags: "टॅग्ज",
  non_fiction: "नॉन-फिक्शन",
  self_help: "स्वत: ची मदत",
  trending: "चर्चेत असलेला विषय",
  best_seller: "बेस्टसेलर",
  notification: "अधिसूचना",
  cart: "कार्ट",
  price_details: "किंमत तपशील",
  all_good_news: "सर्व चांगली बातमी",
  total: "एकूण",
  more_like_this: "यासारखे आणखी",
  proceed_to_buy: "खरेदी करण्यासाठी पुढे जा",
  all: "सर्व",
  downloaded: "डाउनलोड केले",
  search: "शोधा",
  explore_more: "अधिक एक्सप्लोर करा",
  edit_profile: "प्रोफाईल संपादित करा",
  log_out: "बाहेर पडणे",
  purchase_history: "खरेदीचा इतिहास",
  my_preferances: "माझी प्राधान्ये",
  deactivate_account: "खाते निष्क्रिय करा",
  do_you_want_to_logout: "तुम्हाला लॉग आउट करायचे आहे का?",
  cancel: "रद्द करा",
  change_image: "प्रतिमा बदला",
  save: "जतन करा",
  name: "नाव",
  email_ID: "ई - मेल आयडी",
  mobile_number: "मोबाईल क्र.",
  genres: "शैली",
  reset: "रीसेट करा",
  apply: "अर्ज करा",
  continue: "सुरू",
  do_you_want_to_submit_quiz: "तुम्हाला ही क्विझ सबमिट करायची आहे का?",
  do_you_want_to_quit: "आपण सोडू इच्छिता?",
  quit: "सोडा",
  correct_answers: "बरोबर उत्तरे",
  wrong_answers: "चुकीची उत्तरे",
  your_answers: "तुमची उत्तरे",
  do_you_want_to_deactivate_account:
    "तुम्हाला अकाऊंट डिॲक्टिव्हेट\n किंवा डिलीट करायचे आहे का?",
  deactivate: "निष्क्रिय करा",
  post: "पोस्ट",
  videos: "व्हिडिओ",
  subscription: "वर्गणी",
  read_now: "आता वाचा",
  forgot_content:
    "घडते काळजी करू नका. कृपया तुमच्या खात्याशी संबंधित\nपत्ता प्रविष्ट करा",
  signup: "साइनअप करा",
  create_account_here: "येथे तुमचे खाते तयार करा...",
  full_name: "पूर्ण नाव",
  mobile_no: "मोबाईल क्र",
  confirm_password: "पासवर्डची पुष्टी करा",
  i_agree: "मी सहमत आहे ",
  terms: "अटी ",
  policies: " धोरणे",
  only_book_can_help_you: "फक्त पुस्तकेच तुम्हाला मदत करू शकतात",
  intro_one_content:
    "तुमचे ज्ञान वाढवण्यासाठी आणि अधिक यशस्वी होण्यासाठी पुस्तके तुम्हाला मदत करू शकतात.",
  journey_within_pages: "पृष्ठांमध्ये प्रवास",
  intro_two_content:
    "एक उत्तम पुस्तक तुम्हाला अनेकांसह सोडले पाहिजे\nअनुभव आणि शेवटी थोडे थकलेले\n. वाचताना तुम्ही अनेक आयुष्य जगता.",
  friend_in_pages: "पानांमधील मित्र",
  intro_three_content:
    "एक चांगलं पुस्तक हे सर्वोत्कृष्ट मित्र आहे\nआज आणि कायमचे.",
  no_books_found: "कोणतीही पुस्तके सापडली नाहीत",
  you_are_not_connected_to_internet:
    "तुम्ही इंटरनेटशी कनेक्ट केलेले नाही. कृपया आधीच डाउनलोड केलेली पुस्तके वाचा.",
  your_account_has_been_deactivated: "तुमचे खाते निष्क्रिय केले गेले आहे",
  institue_not_allow_to_login: "संस्थेला लॉगिन करण्याची परवानगी नाही",
  google_sign_in_failed: "Google साइन-इन अयशस्वी.",
  sign_in_cancel: "साइन इन रद्द केले",
  sign_in_progress: "प्रक्रियेत",
  play_service_not_available: "प्ले सेवा उपलब्ध नाही",
  something_went_wrong: "काहीतरी चूक झाली",
  please_enter_name: "कृपया आपले नाव प्रविष्ट करा",
  please_enter_email: "कृपया ईमेल प्रविष्ट करा",
  please_enter_valid_email: "कृपया वैध ईमेल प्रविष्ट करा",
  please_enter_mobile_number: "कृपया मोबाईल नंबर टाका",
  please_enter_valid_number: "कृपया वैध मोबाइल नंबर प्रविष्ट करा",
  please_enter_password: "कृपया पासवर्ड टाका",
  password_regex_text:
    "पासवर्ड 1 क्रमांकासह किमान 8 वर्णांचा असणे आवश्यक आहे,\n1 अप्परकेस अक्षर,1 लहान वर्णातील अक्षर आणि 1 विशेष वर्ण.",
  please_enter_confirm_password: "कृपया पुष्टी संकेतशब्द प्रविष्ट करा",
  password_confirm_password_not_match: "पासवर्ड आणि कन्फर्म पासवर्ड जुळत नाहीत",
  please_agree_term_conditions:
    "कृपया सर्व अटी आणि गोपनीयता धोरणास सहमती द्या ",
  google_sign_up_failed: "Google साइन-अप अयशस्वी.",
  enter_confirm_password: "तुमचा पुष्टी संकेतशब्द प्रविष्ट करा",
  new_password_confirm_password_not_match:
    "नवीन पासवर्ड आणि पुष्टीकरण पासवर्ड जुळत नाही",
  password_reset_successfully: "पासवर्ड यशस्वीरित्या रीसेट",
  set_new_password: "नवीन पासवर्ड सेट करा ",
  enter_password_below:
    "खाली नवीन पासवर्ड टाका& आम्ही लॉग इन करू\nतुम्ही लवकरात लवकर!",
  otp_sent_successfully: "तुमच्या ईमेलवर Otp यशस्वीरीत्या पाठवला",
  forget_password: "पासवर्ड विसरलात\n?",
  no_books_for_purchase: "खरेदीसाठी पुस्तके मिळत नाहीत",
  notification_not_found: "सूचना सापडली नाही",
  apply_successfully: "यशस्वीरित्या अर्ज करा",
  thank_you_for_purchase: "तुमच्या खरेदीबद्दल धन्यवाद",
  review_description: "कृपया तुमचे पुनरावलोकन वर्णन प्रविष्ट करा",
  no_books_are_added_for_cart: "कार्टसाठी कोणतीही पुस्तके जोडलेली नाहीत",
};
