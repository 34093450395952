import React, { useContext, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import InstituteLayout from "../layout/InstituteLayout";
import MyBook from "../pages/myBook/MyBook";
import Home from "../pages/home/Home";
import Login from "../pages/authentication/login/Login";
import ForgotPassoword from "../pages/authentication/ForgotPassoword";
import OtherBooks from "../pages/otherBooks/OtherBooks";
import BookDetails from "../pages/bookDetails/BookDetails";
import ReadBook from "../pages/readBook/ReadBook";
import ExploreMore from "../pages/exploreMore/ExploreMore";
import Quiz from "../pages/Quiz/Quiz";
import Account from "../pages/Account/Account";
import Otp from "../pages/authentication/Otp";
import NewPassword from "../pages/authentication/NewPassword";
import SignUp from "../pages/authentication/SignUp";
import { AuthContext } from "../context";

const PageRouter = () => {
  const { user } = useContext(AuthContext);
  console.log(user, "logger in user data");
  const navigate = useNavigate();
  useEffect(() => {
    let userLoggedInStatus = JSON.parse(
      localStorage?.getItem("user_details_json")
    );
    if (user) {
      navigate("/home");
    } else {
      navigate("/");
    }
  }, []);
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      {/* <Route path="/signup" element={<SignUp />} /> */}
      <Route path="/forgotpassword" element={<ForgotPassoword />} />
      <Route path="/otp" element={<Otp />} />
      <Route path="/newpassword" element={<NewPassword />} />
      <Route element={<InstituteLayout />}>
        <Route path="/home" element={<Home />} />
        <Route path="/mybook" element={<MyBook />} />
        <Route path="/otherbooks" element={<OtherBooks />} />
        <Route path="/exploremore" element={<ExploreMore />} />
        <Route path="/quiz" element={<Quiz />} />
        <Route path="/account" element={<Account />} />
        <Route path="/readbook/:id" element={<ReadBook />} />
        <Route path="/aboutbook/:id" element={<BookDetails />} />
      </Route>
    </Routes>
  );
};

export default PageRouter;
