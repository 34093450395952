import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";
import NotificationSidebar from "../pages/notification/NotificationSidebar";

const InstituteLayout = () => {
  const [notificationModal, setNotificationModal] = useState(false);
  useEffect(() => {}, [notificationModal]);
  return (
    <>
      <div className="w-full h-full flex relative">
        <Sidebar />
        <div className="lg:w-[calc(100%-280px)] md:w-full sm:w-full w-full relative ">
          <Header
            notificationModal={notificationModal}
            setNotificationModal={setNotificationModal}
          />
          {notificationModal && (
            <NotificationSidebar
              notificationModal={notificationModal}
              setNotificationModal={setNotificationModal}
            />
          )}

          <main className="pb-2 main-wrapper h-full min-h-screen w-full bg-[#f5f6fa] rounded-[15px_0_0_0] p-3 pt-6 sm:p-4 md:p-6 lg:p-7 relative">
            <Outlet
            // context={[loader, setLoader]}
            />
          </main>
        </div>
      </div>
    </>
  );
};

export default InstituteLayout;
