//REQUEST
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
  getMultipartRequest,
} from "./https";

//CONSTANT
import { WEB_SERVICE } from "../constants";

async function userSignUp(params) {
  let url = WEB_SERVICE.sign_up;
  const result = await postRequest(url, params);
  return result;
}

async function getUserProfile(params) {
  let url = WEB_SERVICE.get_user_profile + `/${params._id}`;
  const result = await getRequest(url);
  return result;
}

async function userLogin(params) {
  let url = WEB_SERVICE.login;
  const result = await postRequest(url, params);
  return result;
}

async function userForgotPassword(params) {
  let url = WEB_SERVICE.forgot_password;
  const result = await putRequest(url, params);
  return result;
}

async function userOtpVerify(params) {
  let url = WEB_SERVICE.otp_verify;
  const result = await putRequest(url, params);
  return result;
}

async function userResetPassword(params) {
  let url = WEB_SERVICE.reset_password;
  const result = await putRequest(url, params);
  return result;
}

async function logOut(params) {
  let url = WEB_SERVICE.log_out + `/${params._id}`;
  const result = await putRequest(url, params);
  return result;
}

async function getUserInfo(params) {
  let url = WEB_SERVICE.get_user_info + `/${params._id}`;
  const result = await getRequest(url, params);
  return result;
}

async function updateUserProfile(params, userId) {
  let url = WEB_SERVICE.update_user_profile + `/${userId}`;
  const result = await putRequest(url, params, "form");
  return result;
}

async function getEbookList(params) {
  let url =
    WEB_SERVICE.get_e_booklist +
    `?type=${params?.type}&language=English&userId=${params.userId}`;
  const result = await getRequest(url);
  return result;
}

async function EbookInfo(userId) {
  let url = WEB_SERVICE.e_bookInfo + `/${userId}`;
  const result = await getRequest(url);
  return result;
}

async function getAllQuizList(params) {
  let url = WEB_SERVICE.get_quiz_list;
  const result = await getRequest(url, params);
  return result;
}

async function addReview(params) {
  let url = WEB_SERVICE.add_review;
  const result = await postRequest(url, params);
  return result;
}

async function addBookCart(params) {
  let url = WEB_SERVICE.add_book_to_cart;
  const result = await postRequest(url, params);
  return result;
}

async function cartBookList(params) {
  let url = WEB_SERVICE.cart_book_list + `${params.userId}`;
  const result = await getRequest(url, {});
  return result;
}

async function deleteCartBook(userId) {
  let url = WEB_SERVICE.delete_book_cart + `/${userId}`;
  const result = await deleteRequest(url);
  return result;
}

async function addPurchaseBookCart(params) {
  let url = WEB_SERVICE.add_to_purchase_book;
  const result = await postRequest(url, params);
  return result;
}

async function updatePurchaseBook(params) {
  let url = WEB_SERVICE.update_purchase_book + `/${params._id}`;
  const result = await putRequest(url);
  return result;
}

async function getPurchaseHistory(params) {
  let url = WEB_SERVICE.get_purchase_history + `?userId=${params.userId}`;
  const result = await getRequest(url);
  return result;
}

async function deactive_user(params) {
  let url =
    WEB_SERVICE.deactivate_user_account +
    `${params._id}?activeStatus=${params.activeStatus}`;
  const result = await putRequest(url, params);
  return result;
}

async function getSearchList(params) {
  let url = WEB_SERVICE.searchList + `?searchText=${params.searchText}`;
  const result = await getRequest(url, {});
  return result;
}

async function getPreference(params) {
  let url =
    WEB_SERVICE.my_preference +
    `?userId=${params?.userId}&preferncesOption=${params.preferncesOption}`;
  const result = await getRequest(url, {});
  return result;
}

async function savePreference(params, selectedItem) {
  if (params.isAuthor) {
    let url = WEB_SERVICE.my_preference + `?userId=${params?.userId}`;

    selectedItem.forEach((e) => {
      url += "&author=" + e;
    });

    const result = await getRequest(url, {});
    return result;
  } else {
    let url = WEB_SERVICE.my_preference + `?userId=${params?.userId}`;

    selectedItem.forEach((e) => {
      url += "&genre=" + e;
    });

    const result = await getRequest(url, {});
    return result;
  }
}

async function resetPreference(params) {
  let url = WEB_SERVICE.reset_my_preference + `${params?.userId}`;
  const result = await putRequest(url, {});
  return result;
}

async function getNotificationList(params) {
  let url = WEB_SERVICE.notification_list + `?userType=${params.userType}`;
  const result = await getRequest(url, {});
  return result;
}

async function updateBookPdfProgress(params) {
  let url =
    WEB_SERVICE.update_book_pdf_status +
    `${params?.userId}` +
    `?bookId=${params.bookId}&totalPages=${params.totalPages}&readPages=${params?.readPages}`;
  const result = await putRequest(url, {});
  return result;
}

async function getMoreItems(params) {
  let url = WEB_SERVICE.get_more_item + `${params?.userId}`;
  const result = await getRequest(url, {});
  return result;
}

async function getProgressOfBook(params) {
  let url =
    WEB_SERVICE.progress_book +
    `?bookId=${params.bookId}&userId=${params?.userId}`;
  const result = await getRequest(url, {});
  return result;
}

async function getMyBooks(params) {
  let url = WEB_SERVICE.my_book_list + `${params?.userId}`;
  const result = await getRequest(url, {});
  return result;
}

async function onGoogleLogin(params) {
  let url = WEB_SERVICE.google_login;
  const result = await getMultipartRequest(url, params);
  return result;
}

async function getCategoryList(params) {
  let url = WEB_SERVICE.category_list;
  const result = await getRequest(url, {});
  return result;
}

async function getFcmTokenUpdate(params) {
  let url = WEB_SERVICE.update_fcm_token + `${params._id}`;
  const result = await putRequest(url, params);
  return result;
}

async function updateUserLanguage(params) {
  let url =
    WEB_SERVICE.updateUserLanguage +
    params?.userId +
    `?language=${params?.language}`;
  const result = await putRequest(url, params);
  return result;
}

async function languageChange(params) {
  let url = WEB_SERVICE.change_language;
  const result = await getRequest(url, {});
  return result;
}

export {
  userSignUp,
  getUserProfile,
  userLogin,
  userForgotPassword,
  userOtpVerify,
  userResetPassword,
  logOut,
  getUserInfo,
  updateUserProfile,
  getEbookList,
  EbookInfo,
  getAllQuizList,
  addReview,
  addBookCart,
  cartBookList,
  deleteCartBook,
  addPurchaseBookCart,
  updatePurchaseBook,
  getPurchaseHistory,
  deactive_user,
  getSearchList,
  getPreference,
  resetPreference,
  getNotificationList,
  updateBookPdfProgress,
  getMoreItems,
  getProgressOfBook,
  getMyBooks,
  savePreference,
  onGoogleLogin,
  getCategoryList,
  getFcmTokenUpdate,
  updateUserLanguage,
  languageChange,
};
