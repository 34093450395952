import React from "react";
import { IMAGES } from "../../assets";
import { useLocation, useNavigate } from "react-router-dom";
import BookCard from "../../components/book/BookCard";
import { useTranslation } from "react-i18next";
import { STRINGS } from "../../constants";

const OtherBooks = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const otheBookItem = location?.state?.otheBookItem;

  const { t } = useTranslation();
  console.log(location, "loction data in other bookes");
  return (
    <div>
      <div className="my-4 flex items-center justify-between">
        <button
          className="w-8 h-8 text-lg font-bold border-slate-400 border flex items-center justify-center rounded-full"
          onClick={() => navigate(-1)}
        >
          <img
            className="w-full h-full"
            alt="backarrow"
            src={IMAGES?.backIcon}
          />
        </button>
        <h2 className="mx-auto text-lg font-bold">{t(STRINGS?.other_books)}</h2>
      </div>

      <div className="grid grid-cols-4 gap-4">
        {otheBookItem?.map((item, index) => {
          return (
            <BookCard
              img={item?.bookImage ?? IMAGES?.richDadPoorIcon}
              name={item?.bookName}
              price={`₹ ${item?.price}`}
              rating={item?.overallRating}
              link={`/otherbooks/The_Psycholology_of_Money`}
              type="vertical"
            />
          );
        })}
      </div>
    </div>
  );
};

export default OtherBooks;
