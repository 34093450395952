import React from "react";

const ProgressBar = ({ percentage, color, height }) => {
  return (
    <div
      style={{ height: height ?? "6px" }}
      className={`w-full relative rounded-2xl my-1 bg-[#E6E6E6]`}
    >
      <div
        className="absolute h-full top-0 left-0 rounded-2xl "
        style={{
          width: `${percentage}%`,
          backgroundColor: color,
        }}
      ></div>
    </div>
  );
};

export default ProgressBar;
