import React, { useContext, useEffect, useState } from "react";
import BookListCard from "../../components/book/BookListCard";
import { AuthContext } from "../../context";
import { useTranslation } from "react-i18next";
import { getMyBooks } from "../../api";
import { STORAGE_KEY, STRINGS } from "../../constants";

const MyBook = (props) => {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();
  const [selectedType, setSelectedType] = useState(
    props?.route?.params?.selectedType || 0
  );
  const [arrayDownload, setArrayDownload] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [bookItem, setBookItem] = useState([]);
  const [isError, setIsError] = useState(null);

  console.log(bookItem, "bookItem in my book page");
  console.log(arrayDownload, "arrayDownload in my book page");
  useEffect(() => {
    onMyBookList();
  }, []);

  async function getDownloadedItem() {
    const arrayStr = await localStorage.getItem(
      STORAGE_KEY.DOWNLOADED_BOOK_ITEM
    );
    if (arrayStr) {
      let array = JSON.parse(arrayStr);
      setArrayDownload(array);
    }
  }
  async function onMyBookList() {
    const params = {
      userId: user?.userInfo?._id,
    };

    setIsLoading(true);
    const result = await getMyBooks(params);
    setIsLoading(false);

    console.log("BOOK LIST", result);

    if (result?.status) {
      const response = result?.data?.data ?? [];
      setBookItem(response);
      if (response?.length == 0) {
        setIsError(t(STRINGS?.no_books_found));
      }
    } else {
      setIsError(result?.error);
    }
  }
  return (
    <div>
      <div className="flex items-center justify-center">
        <h2 className="mx-auto text-lg font-bold">{t(STRINGS?.myBooks)}</h2>
      </div>
      <div className="grid grid-cols-2 mt-4 gap-4">
        <BookListCard
          title={t(STRINGS.all)}
          data={bookItem}
          isError={isError}
        />
        <BookListCard
          title={t(STRINGS.downloaded)}
          data={arrayDownload}
          isError={isError}
        />
      </div>
    </div>
  );
};

export default MyBook;
