import React, { useState } from "react";
import AppButton from "../../components/button/AppButton";
import { IMAGES } from "../../assets";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { SHOW_TOAST } from "../../constants";
import { userForgotPassword, userOtpVerify } from "../../api";
import OTPInput from "react-otp-input";

const Otp = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const email = location?.state?.email;
  const [otpText, setOtpText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [forgotForm, setForgotForm] = useState({
    email: "",
    error: "",
    success: "",
    loader: "",
    otpValidationScreen: false,
    otpValue: "",
    otpError: false,
  });

  const handleChange = (otp) => {
    console.log(otp, "otp values");
    let value = otp;
    setForgotForm((prev) => ({ ...prev, otpValue: value }));
  };

  console.log(email, "email");

  function onOtpCheck() {
    if (!forgotForm?.otpValue) {
      SHOW_TOAST("Please Enter Your Otp", "warning");
    } else {
      onOtpVerify(forgotForm?.otpValue);
    }
  }

  async function onOtpVerify(otpText) {
    const params = {
      emailId: email,
      otp: otpText,
    };

    setIsLoading(true);
    const result = await userOtpVerify(params);
    setIsLoading(false);

    console.log("OTP", JSON.stringify(result));

    if (result?.status) {
      //   props.navigation.navigate(SCREENS.NewPassword.name, {
      //     email: email,
      //   });
      navigate("/newPassword", { state: { email: email } });
    } else {
      SHOW_TOAST(result?.error, "warning");
    }
  }

  async function onForgotPassword() {
    setOtpText(null);
    const params = {
      emailId: email,
    };

    setIsLoading(true);
    const result = await userForgotPassword(params);
    setIsLoading(false);

    console.log("FORGOT_PASS", result);

    if (result?.status) {
      //   clearText();
      setForgotForm((prev) => ({ ...prev, otpValue: "" }));
      SHOW_TOAST("Otp sent successfully on your email", "success");
    } else {
      SHOW_TOAST(result?.error, "error");
    }
  }

  return (
    <div className="w-full  ">
      <div className="w-[100%]    flex h-[100vh]">
        <div className="w-[50%] relative h-full  bg-[#FE6B010F] lg:block md:hidden sm:hidden hidden  ">
          {/* <AuthPageImage ImageUrl={Image} logo={Logo} /> */}
          <div className="flex flex-col items-center justify-between h-full">
            <img
              src={IMAGES?.logo}
              className="w-auto h-24 bottom-0 relative mt-12"
            />
            <img
              src={IMAGES?.reading_ledy}
              className="w-auto h-96 bottom-0 relative"
            />
          </div>
        </div>
        <div className="w-full   lg:w-[50%]  md:w-full  sm:w-full  px-4   sm:px-28  py-2 ">
          <button
            className="w-8 h-8 text-lg font-bold border-slate-400 border flex items-center justify-center rounded-full"
            onClick={() => navigate(-1)}
          >
            <img
              className="w-full h-full"
              alt="backarrow"
              src={IMAGES?.backIcon}
            />
          </button>
          <div className="h-full  flex flex-col justify-center items-center relative">
            <div className=" flex flex-col max-w-[345px]">
              <h2 className="py-1 font-semibold text-3xl">Enter OTP </h2>
              <p className="py-2 text-paragraph pb-3">
                An 4 digit code has been sent to {email}
              </p>
              {/* <LoginForm /> */}

              <div className="w-full">
                <div className="otp-inputs w-full flex items-center justify-center w-100 py- gap-4">
                  <OTPInput
                    value={forgotForm.otpValue}
                    onChange={handleChange}
                    className=""
                    numInputs={4}
                    isInputNum={true}
                    focusStyle={{
                      border: "1px solid red !important ",
                    }}
                    renderSeparator={<span> </span>}
                    renderInput={(props) => (
                      <input
                        style={{ border: "2px solid black", padding: "4px" }}
                        className="outline-none bg-slate-400 p-3 w-full"
                        {...props}
                      />
                    )}
                  />
                </div>
                {forgotForm?.otpError && (
                  <p className="block text-rose-500 text-sm">
                    Please enter valid Otp
                  </p>
                )}
                <div
                  className="text-center text-sm text-[#0034EB] cursor-pointer"
                  onClick={() => onForgotPassword()}
                >
                  <span>Resend OTP</span>
                </div>

                <AppButton
                  onClick={() => onOtpCheck()}
                  btnText={`submit`}
                  disabled={
                    forgotForm?.otpError || forgotForm?.otpValue?.length !== 4
                      ? true
                      : false
                  }
                  className="bg-[#FE6B01] text-white w-full py-2 mt-4 rounded-2xl"
                  btnType={"Submit"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Otp;
