import React, { useEffect, useState } from "react";
import { IMAGES } from "../../../assets";
import { useTranslation } from "react-i18next";
import { STRINGS } from "../../../constants";

const About = ({ data }) => {
  const [selectedLanguageButtonType, setSelectedLanguageButtonType] =
    useState(0);
  // const [videoId, setVideoId] = useState(null);

  const { t } = useTranslation();

  return (
    <>
      <div className="my-2">
        <p className="text-sm">
          {data?.about}
          <span className="underline text-blue-700">
            {t(STRINGS.read_more)}
          </span>
        </p>
      </div>

      <div className="my-2">
        <h2 className="text-base font-medium text-[#8A8A8A]">
          {t(STRINGS.language)}
        </h2>
        <div className="flex items-center gap-2 mt-3">
          <button
            onClick={() => {
              if (data?.bookLanguage?.language == "English") {
                setSelectedLanguageButtonType(0);
              } else {
                setSelectedLanguageButtonType(1);
              }
            }}
            className="bg-[#480D9B] text-white tab-btn py-2 px-5  text-sm border border-slate-400 rounded-2xl"
          >
            {t(STRINGS.english)}
          </button>
          <button
            onClick={() => {
              if (data?.bookLanguage?.language == "English") {
                setSelectedLanguageButtonType(0);
              } else {
                setSelectedLanguageButtonType(1);
              }
            }}
            className="text-slate-500  tab-btn py-2 px-5  text-sm border border-slate-400 rounded-2xl"
          >
            {t(STRINGS.marathi)}
          </button>
        </div>
        <h2 className="text-base font-medium text-[#8A8A8A] mt-2">
          {" "}
          {t(STRINGS.videos)}
        </h2>
        <div className="bg-[#F8F8F8] p-2 rounded-2xl flex items-start justify-start gap-2">
          {/* <img className="h-32" src={IMAGES?.bookLogo} />
          <div>
            <h2>Empowering Summary of IKIGAI</h2>{" "}
            <p>What are the principal and application ?</p>
          </div> */}

          {data?.videoLink && (
            <iframe
              width="560"
              height="250"
              src={`https://www.youtube.com/embed/eDa1U9qJKxo?si=uC4vtVv1pP2NgScA || ${data?.videoLink}`}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          )}
        </div>
      </div>
    </>
  );
};

export default About;
